// This component is responsible for rendering the page to add a new WhatsApp instance.
// The client can add a new WhatsApp instance by entering the name of the instance and generating a QR code to scan with the WhatsApp application.
// The component also displays a help card with the steps to add a new WhatsApp instance.
// Author: Vitor de Jesus

// Documentation: https://briotecnologia.atlassian.net/wiki/spaces/DI1/pages/137363457/Como+adicionar+WhatsApp
// Prototype: https://www.figma.com/design/NqSBP9Hjy6KFGUik7FKy4F/UIUX-design?node-id=637-1023&t=EGN3IbFNlKWQyQKU-4

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import QRCODE from '../../assets/images/QRCODE.png';
import QRCODE2 from '../../assets/images/QRCODE2.png';

import { ChannelCheckQRCode } from '../../api/backend/Channels/ChannelCheckQRCode';
import { ChannelCheck } from '../../api/backend/Channels/ChannelCheck';
import { ChannelAdd } from '../../api/backend/Channels/ChannelAdd';
import { isAuthenticated } from '../../authMiddleware';
import HelpAdd from "../../components/Cards/HelpAdd";

import HeaderPage from '../../components/HeaderPage/HeaderPage';
import Loading from '../../components/Loading/Loading';
import ViewWhatsAppScreen from '../../components/WhatsAppScreen/ViewWhatsAppScreen';
import AlertSnackbar from "../../components/Alerts/AlertSnackbar";
import Footer from '../../components/Footer/Footer';

function ChannelPageAddWhatsApp() {

  const { authenticated } = isAuthenticated();
  const [userInfo, setUserInfo] = useState({});
  let Navigate = useNavigate();

  const [countdown, setCountdown] = useState(45);
  const [countdownActive, setCountdownActive] = useState(false);
  const [exibirQrCode, setExibirQrCode] = useState(false);
  const [instance, setInstance] = useState('');

  const [servers, setServers] = useState([]);
  const [selectedServer, setSelectedServer] = useState('');
  const [showLoading, setShowLoading] = useState(true);
  const [nameIsEmpity, setNameIsEmpity] = useState(false);

  const [expiredQrCode, setExpiredQrCode] = useState(false);
  const [codeGenerated, setCodeGenerated] = useState(false);
  const [qrcode, setQrcode] = useState(QRCODE2);
  const [instanceUUID, setInstanceUUID] = useState('');

  const steps = [
    { desc: "Escolha um nome para o Whatsapp." },
    { desc: "Gere o QR code." },
    { desc: "Escaneie o código com o seu aplicativo." }
  ];

  const [message, setMessage] = useState([
    {
      messages: {
        messages: [{ id: 1, type: 'sms', message: 'messageTemporary', fromMe: true }],
      },
    },
  ]);

  // Effect to check if the user is authenticated
  useEffect(() => {
    if (authenticated) {
      const { decryptedData } = isAuthenticated();
      setUserInfo(decryptedData);
    }
  }, []);

  useEffect(() => {
    console.log('uuid', instanceUUID);
  }, [instanceUUID]);


  useEffect(() => {
    const checkConnection = async () => {
      try {


        const code = instanceUUID;
        const name = instance;

        const resultJson = await ChannelCheck({ code, name });

        if (resultJson.status === 200 && resultJson.uuid === code) {
          Navigate('/canais');
        } else {
        }
      } catch (error) {
        console.error('Error checking connection:', error);
      }
    };

    if (userInfo['UUID']) {
      if (exibirQrCode && countdown > 0) {

        setCountdownActive(true);
        const timer = setTimeout(() => {
          setCountdown((prevCountdown) => prevCountdown - 1);
        }, 1000);

        return () => {
          clearTimeout(timer);
        };
      } else if (countdown === 0 && instanceUUID) {

        setCountdownActive(false);
        checkConnection();
        handleModalClose();

      }
    }
  }, [exibirQrCode, countdown, instanceUUID]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);


  // Function to add a new instance
  const handleAdicionar = async () => {
    try {


      const selectedVersion = servers.find((server) => server.name === selectedServer)?.version || 'VERSION_NOT_FOUND';

      const addInstance = await ChannelAdd();
      const code = addInstance.code;

      setInstanceUUID(code);

      const newInstance = await ChannelCheckQRCode(code)

      const qrcodeUrl = newInstance.qrCode;
      setQrcode(qrcodeUrl);
      setExibirQrCode(true);
      setCountdownActive(true);
      setCodeGenerated(true);
      setExpiredQrCode(false);

    } catch (error) {
      console.error('Error connecting to instance:', error);

    } finally {

    }
  };

  // Function to close the modal
  const handleModalClose = async () => {
    setCountdownActive(false);
    setCountdown(45);
    //setInstance('');
    //setExibirQrCode(false);
    //setQrcode(QRCODE2);
    //setExpiredQrCode(true);
  };
  const [username, setUsername] = useState('');

  // Function to close the snackbar
  if (showLoading) {
    return <Loading open={true} />;
  }

  // Render the page
  return (
    <>
      <HeaderPage title="Add WhatsApp Instance" />

      <Grid container spacing={4} sx={{ padding: { xs: "0 20px", sm: 5 }}} position={'relative'}>

        <Grid item xs={12} lg={6}>

          <HelpAdd Steps={steps} Title="Adicione o seu WhatsApp." />
          {
            expiredQrCode && (
              <AlertSnackbar message={`Seu código expirou. Gere um novo e tente novamente`} severity="warning" open={true} />
            )
          }
          {
            !expiredQrCode && codeGenerated && (
              <AlertSnackbar message={`Código gerado! Realize a leitura com seu aplicativo.`} severity="success" open={true} />
            )
          }

          {
            nameIsEmpity && (
              <AlertSnackbar message={`Escolha um nome para o seu Whatsapp primeiro.`} severity="error" open={true} />
            )
          }

        </Grid>

        <Grid item xs={12} lg={6}>

          <Box component="section" sx={{ backgroundColor: '#fff', borderRadius: '10px', padding: { xs: '20px', sm: '40px' } }}>
            <Typography style={{ color: '#52525B', fontSize: '14px', fontFamily: 'Satoshi', marginBottom: '-5px' }}>Nome do WhatsApp</Typography>
            <TextField
              variant="outlined"
              placeholder='Escolha um nome'
              fullWidth
              margin="normal"
              name="instance"
              value={instance}
              autoFocus={true}
              size="small"
              onChange={(e) => setInstance(e.target.value)}
              sx={{ background: nameIsEmpity ? '#FFDADA' : '#F9F9F9', marginBottom: '20px', borderRadius: '5px' }}
              InputProps={{
                disableUnderline: true,
                endAdornment: (
                  nameIsEmpity ?
                    <svg width="30" height="30" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginTop: '5px' }}>
                      <mask id="mask0_34_1183" maskUnits="userSpaceOnUse" x="0" y="0" width="30" height="30">
                        <rect width="30" height="30" fill="#D9D9D9" />
                      </mask>
                      <g mask="url(#mask0_34_1183)">
                        <path d="M8 10.9743C8.11622 10.9743 8.21367 10.935 8.29233 10.8563C8.371 10.7778 8.41033 10.6804 8.41033 10.5642C8.41033 10.4478 8.371 10.3504 8.29233 10.2718C8.21367 10.1932 8.11622 10.1538 8 10.1538C7.88378 10.1538 7.78633 10.1932 7.70767 10.2718C7.629 10.3504 7.58967 10.4478 7.58967 10.5642C7.58967 10.6804 7.629 10.7778 7.70767 10.8563C7.78633 10.935 7.88378 10.9743 8 10.9743ZM7.66667 8.76917H8.33333V4.76917H7.66667V8.76917ZM8.00217 14C7.1725 14 6.39244 13.8426 5.662 13.5277C4.93167 13.2128 4.29633 12.7854 3.756 12.2457C3.21567 11.7059 2.78794 11.0711 2.47283 10.3413C2.15761 9.61167 2 8.83194 2 8.00217C2 7.1725 2.15744 6.39244 2.47233 5.662C2.78722 4.93167 3.21456 4.29633 3.75433 3.756C4.29411 3.21567 4.92889 2.78794 5.65867 2.47283C6.38833 2.15761 7.16806 2 7.99783 2C8.8275 2 9.60756 2.15744 10.338 2.47233C11.0683 2.78722 11.7037 3.21456 12.244 3.75433C12.7843 4.29411 13.2121 4.92889 13.5272 5.65867C13.8424 6.38833 14 7.16806 14 7.99783C14 8.8275 13.8426 9.60756 13.5277 10.338C13.2128 11.0683 12.7854 11.7037 12.2457 12.244C11.7059 12.7843 11.0711 13.2121 10.3413 13.5272C9.61167 13.8424 8.83194 14 8.00217 14Z" fill="#CD4747" />
                      </g>
                    </svg>
                    : null
                )
              }}
            />
            <div style={{ backgroundImage: `url(${QRCODE})`, backgroundSize: '%80', backgroundPosition: 'top', backgroundRepeat: 'no-repeat' }}>
              <ViewWhatsAppScreen messages={message} type="qrcode" qrCode={qrcode} handleGenerateQRCode={handleAdicionar} name={instance} countdownActive={countdownActive} countdown={countdown} />
            </div>
          </Box>
        </Grid>

        <Box sx={{ display: { md: 'flex', lg: 'inherit' }, alignItems: 'start', position: { lg: 'fixed' }, bottom: { lg: 0 }, padding: { xs: '20px 0 0 40px', lg: '0 0 0 40px' } }}>
          <Footer />
        </Box>
      </Grid>


    </>
  );
}

export default ChannelPageAddWhatsApp
