import React from 'react'

const HamburguerMenu = () => {
    return (
        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.625 11.25H3.375" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M23.625 6.75H3.375" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M23.625 15.75H3.375" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M23.625 20.25H3.375" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default HamburguerMenu