// Description: This component render a Card List
// Update: 12/08/2024

// React Components and DOM Elements
import React, { useEffect, useState } from 'react'

// Material UI Components
import { Box, Grid, Rating } from '@mui/material'

// Components and functions from the project
import StatusIcon from '../StatusLabel/StatusIcon'
import Titles from "../Titles/Titles"

const CreateCardList = ({ title, text, icon, iconColor, iconBackground, titleColor, handleClick, alreadyPaid ,positionLeft, heightIcon }) => {

    const [showAlert, setShowAlert] = useState(false);
    const [tooltipClass, setTooltipClass] = useState('');
    const [isMainContentCollapsed, setIsMainContentCollapsed] = useState(false);
  
    useEffect(() => {
      const mainContent = document.querySelector('.MainContent');
      if (mainContent) {
        setIsMainContentCollapsed(mainContent.classList.contains('collapsed'));
      }
    }, [showAlert]);
    
    return (
        <Grid
            item
            xs={12}
            lg={5.8}
            sx={{
                display: "flex",
                gap: "20px",
                padding: "20px",
                alignItems: "center",
                height: "127px",
                border: "1px solid #D9D9DC",
                borderRadius: "12px",
                background: "#fff",
                position: "relative",
                cursor: "pointer",
            }}
            onClick={handleClick}
        >
            <Box
                display={"flex"}
                sx={{
                    minWidth: "79px",
                    minHeight: "79px",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "6px",
                    background: iconBackground ? iconBackground : "#DDD4FF",
                   
                }}
            >
                <StatusIcon status={icon} color={iconColor} height = {heightIcon ? heightIcon : "30px"} width = {heightIcon ? heightIcon : "30px"} />
            </Box>
            <Box display={"flex"} flexDirection={"column"} justifyContent={"space-between"} height={"79px"} maxWidth={"80%"}>
                <Titles styles={{ fontSize: { xs: "16px", sm: "20px" }}} color={titleColor ? titleColor : "#8033F4"} children={title} font={"medium"} />
                <Titles styles={{ fontSize: { xs: "13px", sm: "16px" }}} color={"#000"} children={text} />
            </Box>

            {
                alreadyPaid && (
                    <div
                        className='tooltip-wrapper'
                        style={{
                            position: "absolute",
                            top: "10px",
                            right: "10px",
                            cursor: "pointer"
                        }}
                        onMouseEnter={() => {
                            setShowAlert(true);
                            setTooltipClass('show-tooltip');
                        }}
                        onMouseLeave={() => {
                            setShowAlert(false);
                            setTooltipClass('');
                        }}
                    >
                        <StatusIcon status={"Star"} height={"25px"} width={"24px"} color={"#DEA73F"} />
                    </div>
                )
            }

            {
                showAlert && (
                    <div
                        className={`tooltip ${tooltipClass}`}
                        style={{
                            background: "#E5E5E5",
                            color: "#848484",
                            padding: "15px",
                            borderRadius: "8px",
                            fontFamily: "Satoshi",
                            maxWidth: "100vw",
                            display: "flex",
                            gap: "10px",
                            alignItems: "center",
                            position: "absolute",
                            top: "-30px",
                            left: isMainContentCollapsed ? "70%" : "65%",
                            zIndex: 10,
                        }}
                    >
                        <StatusIcon status={'Help'} width={"16px"} height={"16px"} color={"#848484"} />
                        Este é um serviço pago
                    </div>
                )
            }
        </Grid>
    )
}

export default CreateCardList