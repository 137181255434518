// This endpoint is used to add a new email channel to the user.
// Author: Vitor de Jesus

// Documentation: 

import axios from 'axios';

export async function ChannelAddEmail(data) {
    try {

        const token = localStorage.getItem('jwt_token');

        const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/channel/email/add`, 
            data,
            { 
                headers: { 
                    Authorization: `Bearer ${token}` 
                },
            }
        );
        
        const responseData = response.data;

        return responseData;
    } catch (error) {
        console.error('Erro ao buscar dados da API:', error);
        throw new Error('Erro ao buscar dados da API');
    }
}
