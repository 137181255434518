// Description: Render the sidebar of the configuration route
// Updated: 26/11/2024
// Author: Simon Franklin

// React
import React, { useState } from 'react';

// Material UI Components
import { Box, Typography } from '@mui/material'

// Components and functions for the project
import Config from '../../assets/Icons/components/Config'
import Info from '../../assets/Icons/components/Info';
import PaymentInfo from '../../assets/Icons/components/PaymentInfo';
import Security from '../../assets/Icons/components/Security';
import User from '../../assets/Icons/components/User';
import Send from '../../assets/Icons/components/Send';
import Support from '../../assets/Icons/components/Support';

// Sidebar
export const sidebarDatabase = [
  {
    icon: Info,
    name: 'Informações Pessoais',
    path: 'informaçõespessoais',
    fields: ['Tipo de Usuário', 'CPF ou CNPJ', 'Número para Contato', 'Endereço']
  },
  {
    icon: PaymentInfo,
    name: 'Informações de Pagamento',
    path: 'informaçõesdepagamento',
    fields: ['Métodos de pagamento', 'Histórico de transações', 'Faturas']
  },
  {
    icon: Security,
    name: 'Segurança e Login',
    path: 'segurançaelogin',
    fields: ['Alterar senha', 'Atualizar email ou Login']
  },
  {
    icon: User,
    name: 'Personalização do Perfil',
    path: 'personalização',
    fields: ['Alterar foto de perfil', 'Adicionar biografia ou descrição']
  },
  {
    icon: Send,
    name: 'Gerenciamento de Planos',
    path: 'informaçõespessoais',
    fields: ['Visualizar plano atual e detalhes', 'Atualizar ou fazer upgrade de plano', 'Cancelar assinatura ou reativar plano']
  },
  {
    icon: Support,
    name: 'Suporte e Ajuda',
    path: 'suporteeajuda',
    fields: ['Acessar FAQ', 'Solicitar Suporte', 'Histórico de interações com o suporte']
  },
]

// Render the component
const Sidebar = ({ selected, setSelected }) => {
  const [itemHover, setItemHover] = useState('');

  return (
    <Box component={'section'} sx={{
      display: 'flex',
      flexDirection: 'column',
      padding: '52px 44px 0 40px',
      width: '393px'
    }}>
      <Box sx={{ display: 'flex', gap: '19px', alignItems: 'center', marginBottom: '50px' }}>
        <Config />
        <Typography sx={{ fontSize: '30px', fontWeight: '600' }}>
          Configurações
        </Typography>
      </Box>
      {
        sidebarDatabase.map((item) => (
          <Box
            onMouseOver={() => setItemHover(item.name)}
            onMouseOut={() => setItemHover('')}
            onClick={() => setSelected(item)}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '24px',
              height: '35px',
              width: '100%',
              position: 'relative',
              marginBottom: '16px',
              cursor: 'pointer'
            }} key={item.name}>
            <Box sx={{ position: 'absolute', bottom: '0', left: '0', border: (selected.name === item.name || itemHover === item.name) ? '1.5px solid #8142FC' : '0px solid #8142FC', width: (selected.name === item.name || itemHover === item.name) ? '100%' : '0%', transition: '.5s ease-in-out' }} />
            <item.icon />
            <Typography sx={{ fontSize: '18px', fontWeight: '600', color: (selected.name === item.name || itemHover === item.name) ? '#303035' : '#52525B' }} children={item.name} />
          </Box>
        ))
      }
    </Box>
  )
}

export default Sidebar