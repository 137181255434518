// Description: Navigation Menu that allow the user to navigate into the mobile version of the application
// Updated: 02/12/2024
// Author: Simon Franklin

// React
import React, { useEffect, useState } from 'react'

//Material UI Components
import { Typography } from '@mui/material';

// Components and functions for the project
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { checkAuth, getLocation, handleLogout } from '../../utils/utils';
import { isAuthenticated } from '../../authMiddleware';
import AlternativeAvatar from '../../assets/images/avatar-padrao.png';

// Links data
const navigationLinks = [
    { path: "/painel", text: "Painel" },
    { path: "/relatorios", text: "Relatórios" },
    { path: "/canais", text: "Canais" },
    { path: "/modelos", text: "Modelos" },
    { path: "/listagens", text: "Listagens" },
    { path: "/campanhas", text: "Campanhas" },
    { path: "/apis", text: "APIs" },
    { path: "/suporte", text: "Suporte" }

];

// Render the Component
const NavigationMenu = ({ open, setOpen }) => {
    // States and variables
    const location = useLocation();
    const navigate = useNavigate();
    const { setUserAuthenticated, userAuthenticated } = useAuth();
    const [userInfo, setUserInfo] = useState(null);
    const [userAvatar, setUserAvatar] = useState(null);
    const [isAvatarLoaded, setIsAvatarLoaded] = useState(null);
    const [loading, setLoading] = useState(null);

    // This function check if the user is authenticated them return the path the link will have
    const handleNavigate = (path) => {
        const authStatus = checkAuth()

        if (authStatus === true) {
            navigate(path)
        } else {
            setUserAuthenticated(false);
            navigate("/");
        }
    };

    // Effect responsible for verifying if the user is authenticated
    useEffect(() => {
        if (userAuthenticated) {
            const { decryptedData } = isAuthenticated();
            setUserInfo(decryptedData);
            console.log('decryptedData:', decryptedData);

        }
    }, [userAuthenticated]);

    // Effect responsible for fetching the user's avatar
    useEffect(() => {
        if (userAuthenticated && userInfo && userInfo['UUID']) {
            const userHash = userInfo['UUID'] + '.png';
            console.log('userHash:', userHash);

            fetchUserAvatar(userHash);
        }
    }, [userInfo, userAuthenticated]);
    

    const fetchUserAvatar = async (userHash) => {
        try {
            const imageUrl = 'https://files123zap.s3.amazonaws.com/avatar_users/' + userHash;
            const img = new Image();
            img.onload = () => {
                setUserAvatar(imageUrl);
                setIsAvatarLoaded(true);
                setLoading(false);
            };
            img.onerror = () => {
                console.error('Erro ao carregar a imagem:', imageUrl);
                setUserAvatar(AlternativeAvatar);
                setIsAvatarLoaded(true);
                setLoading(false);
            };
            img.src = imageUrl;
        } catch (error) {
            console.error('Erro ao buscar o avatar do usuário:', error);
            setUserAvatar(AlternativeAvatar);
            setIsAvatarLoaded(true);
            setLoading(false);
        }
    };

    return (
        <>
            <div onClick={() => setOpen(false)} style={{ display: open ? 'block' : 'none', minHeight: '100vh', minWidth: '100%', position: 'fixed', background: 'black', opacity: '0.6', transition: '.4s ease-in-out', zIndex: '30' }} />
            <div style={{
                position: 'fixed',
                width: '224px',
                background: '#FFF',
                padding: '35px 30px',
                zIndex: '30',
                display: 'flex',
                flexDirection: 'column',
                top: '0',
                right: open ? '0' : '-224px',
                height: '100vh',
                transition: '.4s ease-in-out'
            }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '13px', height: '40px', width: '100%', marginBottom: '40px' }}>
                    {userAvatar && <img src={userAvatar} alt="Avatar" style={{ objectFit: 'contain', width: '40px', height: '40px', borderRadius: '50%' }} />}
                    <Typography children={userInfo !== null ? userInfo['NAME'] : 'Usuário'} color={'#848484'} fontWeight={'500'} fontSize={'14px'} justifySelf={'end'} />
                </div>
                {
                    navigationLinks.map((item) => (
                        <div onClick={() => (handleNavigate(item.path), setOpen(false))} style={{ color: getLocation(location.pathname) === item.path ? '#8142FC' : '#848484', fontWeight: '500', marginBottom: '20px', cursor: 'pointer', fontSize: '14px' }}>
                            {item.text}
                        </div>
                    ))
                }

                <div onClick={() => handleLogout(userInfo)} style={{ color: '#848484', fontWeight: '500', marginBottom: '20px', cursor: 'pointer', fontSize: '14px' }}>
                    Sair
                </div>
            </div>
        </>
    )
}

export default NavigationMenu