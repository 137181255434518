// This page is responsible for generating charges from a spreadsheet
// The user must select a spreadsheet and then select the fields that will be used to generate the charges
// Author: Vitor de Jesus

// Prototype: https://www.figma.com/design/NqSBP9Hjy6KFGUik7FKy4F/UIUX-design?node-id=2035-5252&t=vGcPbn62U5gjfpZ4-4

// React Components
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

// Material UI Components
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { FormControl, InputLabel, Typography, TextField, Select, MenuItem, Button, FormHelperText } from "@mui/material";
import { Tab, Tabs } from "@mui/material";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination } from '@mui/material';

// Components and Functions from the project
import AlertSnackbar from "../../components/Alerts/AlertSnackbar";
import HelpAdd from "../../components/Cards/HelpAdd";
import HeaderPage from '../../components/HeaderPage/HeaderPage'
import ModalR from "../../components/Modals/ModalR";
import ThemeColors from "../../components/ThemeColors/ThemeColors";

import { GetVariables } from "../../api/backend/Templates/GetVariables";
import { isAuthenticated } from "../../authMiddleware";
import Loading from "../../components/Loading/Loading";

import DynamicTable from "../../components/Tables/DynamicTable";
import { PaidOutlined } from "@mui/icons-material";
import { InsertCharge } from "../../api/n8n/Listagens/InsertCharge";
import ChooseLayout from "./ChooseLayout";
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded';
import { Next } from "react-bootstrap/esm/PageItem";

// Render Form to add new sheets
function SheetsForm({ setDataR, copyRespostas, setCopyRespostas, setErrors, errors, setTabValue, setErroLoading, setItems }) {

  // State to authenticate control
  const { authenticated } = isAuthenticated();
  const [userInfo, setUserInfo] = useState({});
  const navigate = useNavigate();

  // States to control the form fields
  const [formErros, setFormErros] = useState({});
  const [setFormateList, setListagensOptions] = useState([]);
  const [formateSelected, setFormateSelected] = useState();
  const [loading, setLoading] = useState(false);

  const listOptions = [
    { id: 2, title: "Google planilhas" }
  ];

  const [respostas, setRespostas] = useState({
    nameSheets: copyRespostas?.nameSheets || "",
    descriptionSheets: copyRespostas?.descriptionSheets || "",
    linkSheets: copyRespostas?.linkSheets || "",
    formatSheets: copyRespostas?.formatSheets || "",
    pix: copyRespostas?.pix || "",
  });

  useEffect(() => {
    setListagensOptions(listOptions);
    if (!authenticated) {
      navigate('/login');
    } else {
      const { decryptedData } = isAuthenticated();
      setUserInfo(decryptedData);
    }
  }, [authenticated]);

  async function CheckSheetsPermission() {

    setLoading(true);

    try {

      if (!respostas.nameSheets) formErros.nameSheets = "Campo obrigatório";
      if (!respostas.descriptionSheets) formErros.descriptionSheets = "Campo obrigatório";
      if (!respostas.linkSheets && respostas.formatSheets === "Google planilhas") formErros.linkSheets = "Campo obrigatório";
      if (!respostas.formatSheets) formErros.formatSheets = "Campo obrigatório";
      if (!respostas.pix) formErros.pix = "Campo obrigatório";

      if (Object.keys(formErros).length > 0) {
        setCopyRespostas(respostas);
        setErrors(formErros);
        return;
      }

      const response = await axios.post('https://api.123zap.com.br/webhook/CheckSheetsPermission', {
        uuid_account: userInfo['UUID'],
        full_token: userInfo['TOKEN'],
        listagem: respostas.linkSheets,
        id: userInfo['ID']
      });

      setItems(response.data);

      const items = Object.entries(response.data["0"]["json"]).map(([field, value]) => ({
        field,
        value
      }));

      if (items.length > 0) {
        setDataR(items);
        setTabValue(1);
        setErroLoading(false);
      } else {
        setErroLoading(true);
        formErros.linkSheets = "Link inválido";
        setErrors(formErros);
      }

    } catch (error) {
      console.error('Erro ao obter dados da API:', error);
      setLoading(false);
      setErroLoading(true);
      formErros.linkSheets = "Ocorreu um erro com o link";
      setErrors(formErros);
    } finally {
      setCopyRespostas(respostas);
      setErrors(formErros);
      setLoading(false);
    }

  }

  const handleInputChange = (name, value) => {
    setRespostas((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };



  return (
    <Grid item xs={12} md={12} style={{ 'background': '#FFF', 'borderRadius': '12px', 'padding': '20px' }}>
      <FormControl fullWidth sx={{ marginBottom: 2 }}>
        <TextField
          variant="outlined"
          name="nameSheets"
          label="Digite um título para o faturamento"
          value={respostas.nameSheets || ""}
          onChange={(e) => handleInputChange("nameSheets", e.target.value)}
          sx={{ background: errors.nameSheets ? '#F8D6D3' : respostas.nameSheets ? '#F5F3FF' : '#F9F9F9', marginTop: '5px', borderRadius: '12px' }}
          error={errors.nameSheets}
          InputLabelProps={{
            style: { color: ThemeColors.gray50 }
          }}
        />
        {errors.nameSheets && <FormHelperText style={{ color: "red" }}>{errors.nameSheets}</FormHelperText>}
      </FormControl>
      <FormControl fullWidth sx={{ marginBottom: 2 }}>
        <TextField
          name="descriptionSheets"
          label="Digite uma descrição para o faturamento"
          sx={{ background: errors.descriptionSheets ? '#F8D6D3' : respostas.descriptionSheets ? '#F5F3FF' : '#F9F9F9', marginTop: '5px', borderRadius: '12px' }}
          value={respostas.descriptionSheets || ""}
          onChange={(e) => handleInputChange("descriptionSheets", e.target.value)}
          error={errors.descriptionSheets}
          InputLabelProps={{
            style: { color: ThemeColors.gray50 }
          }}
        />
        {errors.descriptionSheets && <FormHelperText style={{ color: "red" }}>{errors.descriptionSheets}</FormHelperText>}
      </FormControl>
      <FormControl fullWidth sx={{ marginBottom: 2 }}>
        <InputLabel
          style={{ color: ThemeColors.gray50 }}
        >Deseja ativar PIX para receber pagamentos?</InputLabel>
        <Select
          name="pix"
          label="Deseja ativar PIX para receber pagamentos?"
          value={respostas.pix || ""}
          onChange={(e) => handleInputChange("pix", e.target.value)}
          sx={{ background: errors.pix ? '#F8D6D3' : respostas.pix ? '#F5F3FF' : '#F9F9F9', marginTop: '5px', borderRadius: '12px' }}
          required
        >
          <MenuItem value="Sim">
            Sim
          </MenuItem>
          <MenuItem value="Não">
            Não
          </MenuItem>
        </Select>
        {!!errors.pix && <FormHelperText style={{ color: "red" }}>{errors.pix}</FormHelperText>}
      </FormControl>
      <FormControl fullWidth sx={{ marginBottom: 2 }} error={!!errors.formatSheets}>
        <InputLabel
          style={{ color: ThemeColors.gray50 }}
        >Em qual formato a sua planilha está?</InputLabel>
        <Select
          name="formatSheets"
          label="Em qual formato a sua planilha está?"
          value={respostas.formatSheets || ""}
          onChange={(e) => {
            handleInputChange("formatSheets", e.target.value);
            setFormateSelected(setFormateList.find((item) => item.title === e.target.value));
          }}
          sx={{ background: errors.formatSheets ? '#F8D6D3' : respostas.formatSheets ? '#F5F3FF' : '#F9F9F9', marginTop: '5px', borderRadius: '12px' }}
          required
        >
          {setFormateList.map((option) => (
            <MenuItem key={option.id} value={option.title}>
              {option.title}
            </MenuItem>
          ))}
        </Select>
        {!!errors.formatSheets && <FormHelperText style={{ color: "red" }}>{errors.formatSheets}</FormHelperText>}
      </FormControl>
      {respostas.formatSheets === "Google planilhas" && (
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <TextField
            autoFocus
            name="linkSheets"
            label="Digite ou cole aqui o link da sua planilha:"
            sx={{ background: errors.linkSheets ? '#F8D6D3' : respostas.linkSheets ? '#F5F3FF' : '#F9F9F9', marginTop: '5px', borderRadius: '12px' }}
            value={respostas.linkSheets || ""}
            onChange={(e) => handleInputChange("linkSheets", e.target.value)}
            error={errors.linkSheets}
            fullWidth
            InputLabelProps={{
              style: { color: ThemeColors.gray50 }
            }}
          />
          {errors.linkSheets && <FormHelperText style={{ color: "red" }}>{errors.linkSheets}</FormHelperText>}
        </FormControl>
      )}
      <Button
        variant="contained"
        onClick={() => {
          setLoading(true);
          CheckSheetsPermission();
        }}
        style={{ padding: '20px', marginTop: '10px' }}
        fullWidth
        disabled={loading}
      >
        Carregar dados
      </Button>
    </Grid>
  );
}

function DataDisplayTable({ data, setData, handleSubmit, variablesOfTemplate, page, setPage }) {

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [values, setValues] = useState({});

  useEffect(() => {
    const startIndex = page * rowsPerPage;
    const currentItems = data.slice(startIndex, startIndex + rowsPerPage);
    const newInputValues = {};

    currentItems.forEach((item, i) => {
      const absoluteIndex = startIndex + i;
      newInputValues[absoluteIndex] = item.variable || '';
    })
    setValues(newInputValues);
  }, [data, page])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
  };

  const handleChangeSelect = (value, index) => {
    setValues({
      ...values,
      [index]: value
    });

    setData(prevData =>
      prevData.map((dataItem, dataIndex) =>
        dataIndex === index ? { ...dataItem, variable: value } : dataItem
      )
    );
  };

  const verifyIfVariableIsSelected = (variable) => {
    return data.some(item => item.variable === variable);
  }

  const renderSelects = () => {
    const startIndex = page * rowsPerPage;
    const currentItems = data.slice(startIndex, startIndex + rowsPerPage);
    const updatedField = (field) => {
      const variables = Object.values(variablesOfTemplate).flat();
      return variables.includes(field) ? field : "Selecione";
    }


    return currentItems.map((item, index) => {
      const absoluteIndex = startIndex + index;
      return (
        <TableRow key={absoluteIndex}>
          <TableCell>{item.field}</TableCell>
          <TableCell>
            <Select
              labelId={"type_send_id" + index}
              id={"type_send" + index}
              value={values[absoluteIndex] ? values[absoluteIndex] : updatedField()}
              onChange={(e) => {
                handleChangeSelect(e.target.value, absoluteIndex)
              }} 
              fullWidth
              style={{ boxShadow: 'none' }} // Remove o sombreamento do Select
              MenuProps={{
                PaperProps: {
                  style: { boxShadow: 'none', border: '1px solid #d3d3d3' }
                },
              }}
              size="small"
              sx={{ background: values[absoluteIndex] ? '#F5F3FF' : '#F9F9F9', marginTop: '5px', borderRadius: '12px' }}
            >
              <MenuItem value="Selecione">Selecione</MenuItem>
              {Object.values(variablesOfTemplate).flat().map((value) => (
                <MenuItem key={value} value={value} disabled={verifyIfVariableIsSelected(value)}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </TableCell>
        </TableRow>
      )
    })
  }

  return (
    <Grid item xs={12} md={12} style={{ 'background': '#FFF', 'borderRadius': '12px' }}>
      <TableContainer style={{ marginBottom: '10px', padding: '0px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>CAMPO</TableCell>
              <TableCell>REPRESENTA</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {renderSelects()}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Button variant="contained" color="primary" onClick={handleSubmit} fullWidth style={{ padding: '20px', marginTop: '10px' }}>
        Verificar dados
      </Button>
    </Grid>
  );
}

const DynamicTableCharge = ({ data, dataR, setSubmitTable, handleSubmit, itemsFiltered, setItemsFiltered, setRenderTable, loading }) => {

  const items = Object.keys(data)
    .filter(key => key !== 'status')
    .map(key => data[key].json);

  const itemsOK = items.filter(item =>
    Object.values(item).every(value => value !== '' && value !== undefined && value !== null)
  );

  const fieldToVariableMap = dataR.reduce((acc, item) => {
    if (item.variable) {
      acc[item.field] = item.variable;
    }
    return acc;
  }, {});

  const columns = itemsOK.length > 0 ? Object.keys(itemsOK[0]) : [];

  const filteredColumns = columns
    .filter(column => fieldToVariableMap[column])
    .map(column => ({
      header: fieldToVariableMap[column],
      field: column
    }));

  const totalAmountColumn = filteredColumns.find(column => column.header === 'Valor_Total');

  const subtotal = itemsOK.reduce((acc, item) => {
    return acc + parseFloat(item[totalAmountColumn.field
    ] || 0);
  }
    , 0);

  const subtotalGeneral = items.reduce((acc, item) => {
    return acc + parseFloat(item[totalAmountColumn.field
    ] || 0);
  }
    , 0);

  const diference = subtotalGeneral - subtotal;

  return (

    <>
      <DynamicTable
        title="Charges"
        data={items}
        columns={[
          { header: '', field: 'icon', render: () => <PaidOutlined style={{ margin: '15px', color: ThemeColors.green500 }} /> },
          ...filteredColumns,
        ]}
        actions={[]}
        button={{
          icon: <KeyboardBackspaceRoundedIcon style={{ color: '#FFF' }}
          />, tooltip: 'Voltar para padronização',
          action: () => { setRenderTable(false) }
        }}
        activeSearch={true}
        titleSearch={'Pesquisar Cobrança'}
        titleLabel={'Cobranças por página'}
      />
      <Box sx={{ padding: 2, backgroundColor: ThemeColors.purple200, borderRadius: 1, textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Box sx={{ padding: 2, backgroundColor: ThemeColors.purple200, borderRadius: 1 }}>
              <Typography variant="body1">Total de cobranças: {items.length}</Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box sx={{ padding: 2, backgroundColor: ThemeColors.purple200, borderRadius: 1 }}>
              <Typography variant="body1">Total geral: R$ {subtotalGeneral.toFixed(2)}</Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box sx={{ padding: 2, backgroundColor: ThemeColors.purple200, borderRadius: 1 }}>
              <Typography variant="body1">Cobranças válidas: {itemsOK.length}</Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box sx={{ padding: 2, backgroundColor: ThemeColors.purple200, borderRadius: 1 }}>
              <Typography variant="body1">Total valido: R$ {subtotal.toFixed(2)}</Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box sx={{ padding: 2, backgroundColor: ThemeColors.purple200, borderRadius: 1 }}>
              <Typography variant="body1">Diferença: R$ {diference.toFixed(2)}</Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                handleSubmit();
                setSubmitTable(true);
              }}
              fullWidth
              sx={{ height: '100%', borderRadius: 1, fontSize: '16px' }}
              disabled={loading}
            >
              Gerar cobranças
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

function PageAddSheets() {

  const { authenticated } = isAuthenticated();
  const [userInfo, setUserInfo] = useState({});
  const navigate = useNavigate();

  const [copyRespostas, setCopyRespostas] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [countEmptyVariables, setCountEmptyVariables] = useState(0);
  const [dataR, setDataR] = useState([]);
  const [errors, setErrors] = useState({});
  const [fieldChannelSelected, setFieldChannelSelected] = useState(null);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [verifyEmptyVariables, setVerifyEmptyVariables] = useState(false);
  const [erroLoading, setErroLoading] = useState(false);
  const [showLoading, setShowLoading] = useState(true);
  const [variablesOfTemplate, setVariablesOfTemplate] = useState({});
  const [padronizationPage, setPadronizationPage] = useState(0);
  const [fieldCPFORCNPJ, setFieldCPFORCNPJ] = useState(null);
  const [fieldTotalAmount, setFieldTotalAmount] = useState(null);
  const [fieldNomeORFullNome, setFieldNomeORFullNome] = useState(null);
  const [fieldDueDate, setFieldDueDate] = useState(null);
  const [FullField, setFullField] = useState(null);

  const [requiredFields, setRequiredFields] = useState(0);

  const [items, setItems] = useState([]);
  const [itemsFiltered, setItemsFiltered] = useState([]);
  const [renderTable, setRenderTable] = useState(false);
  const [submitTable, setSubmitTable] = useState(false);
  const [loading, setLoading] = useState(false);

  const steps = [
    { desc: "Escolha um nome para identificar a cobrança" },
    { desc: "Crie uma descrição para a cobrança" },
    { desc: "Deseja gerar PIX unico para cada cobrança?" },
    { desc: "Selecione o formato da planilha" },
    { desc: 'Clique em "Carregar dados" para visualizar os campos da planilha' },
    { desc: "Para cada campo, selecione o que ele representa" }
  ];

  useEffect(() => {
    if (authenticated) {
      const { decryptedData } = isAuthenticated();
      setUserInfo(decryptedData);
    }
  }, []);

  useEffect(() => {
    if (userInfo['EMAIL']) {
      fetchData();
      console.log("Pegando os dados")
    }
  }, [userInfo]);

  const fetchData = async () => {
    try {

      const resultJson = await GetVariables();

      const result = resultJson.reduce((acc, item) => {
        const category = item[1];
        if (!acc[category]) {
          acc[category] = [];
        }
        acc[category].push(item[0]);
        return acc;
      }, {});

      setVariablesOfTemplate(result);
    } catch (error) {
      console.error('Error fetching data from API:', error);
    }
  };

  function TabPanel(props) {

    const { children, value, index, ...other } = props;
    const [tabValue, setTabValue] = useState(0);

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const preSubmit = () => {


    setRequiredFields(0);

    const newErrors = {};
    if (!copyRespostas.nameSheets) newErrors.nameSheets = "Campo obrigatório";
    if (!copyRespostas.descriptionSheets) newErrors.descriptionSheets = "Campo obrigatório";
    if (!copyRespostas.linkSheets && copyRespostas.formatSheets === "Google planilhas") newErrors.linkSheets = "Campo obrigatório";
    if (!copyRespostas.formatSheets) newErrors.formatSheets = "Campo obrigatório";
    if (!copyRespostas.pix) newErrors.pix = "Campo obrigatório";

    setErrors(newErrors);

    let variables = dataR.map((item) => {
      return {
        field: item.field,
        variable: item.variable
      }
    });

    const hasEmptyVariable = variables.some((item) => !item.variable);
    const countEmptyVariable = variables.filter((item) => !item.variable).length;

    setVerifyEmptyVariables(hasEmptyVariable);
    setCountEmptyVariables(countEmptyVariable);

    const variablesEmail = variables.filter((item) => item.variable === 'Email');

    const variableCPFORCNPJ = variables.filter((item) => item.variable === 'CPF_CNPJ');
    const variableTotalAmount = variables.filter((item) => item.variable === 'Valor_Total');
    const variableNome = variables.filter((item) => item.variable === 'Nome');
    const variableFullNome = variables.filter((item) => item.variable === 'Nome_Completo');
    const variableDueDate = variables.filter((item) => item.variable === 'Data_Vencimento');


    console.log(variableTotalAmount, variableNome, variableFullNome, 'variables');

    console.log(requiredFields, 'requiredFields');

    if (variableCPFORCNPJ.length === 0) {
      setFieldCPFORCNPJ(false);
      setRequiredFields(prev => prev + 1);
      console.log('entrou aqui 1')
    } else {
      setFieldCPFORCNPJ(true);
    }

    if (variableTotalAmount.length === 0) {
      setFieldTotalAmount(false);
      setRequiredFields(prev => prev + 1);
      console.log('entrou aqui 2')
    } else {
      setFieldTotalAmount(true);
    }

    if (variableNome.length === 0 && variableFullNome.length === 0) {
      setFieldNomeORFullNome(false);
      setRequiredFields(prev => prev + 1);
      console.log('entrou aqui 3')
    } else {
      setFieldNomeORFullNome(true);
    }



    if (variableDueDate.length === 0) {
      setFieldDueDate(false);
      setRequiredFields(prev => prev + 1);
      console.log('entrou aqui 5')
    } else {
      setFieldDueDate(true);
    }



    setOpenModalConfirm(true);

  }

  const handleSubmit = async () => {

    if (!submitTable) {
      setOpenModalConfirm(false);
      setRenderTable(true);
    } else {
      console.log('entrou aqui 2');
      setLoading(true);

      try {
        let variables = dataR.map((item) => {
          return {
            field: item.field,
            variable: item.variable
          };
        });

        let dados = { ...copyRespostas, variables, items , colorCharge, layoutCharge, logoCharge };
        const response = await InsertCharge(dados, userInfo['UUID']);

        if (response.message === "insert") {
          navigate('/financeiro');
        } else {
        }
      } catch (error) {
        console.error('Error during submit:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleCloseModalConfirm = () => {
    setOpenModalConfirm(false);
  }

  useEffect(() => {
    // Simulate a API call
    setTimeout(() => setShowLoading(false), 1000);
  }, []);

  const [colorCharge, setColorCharge] = useState('');
  const [layoutCharge, setLayoutCharge] = useState('');
  const [logoCharge, setLogoCharge] = useState('');
  const [templates, setTemplates] = useState([
    { index: 0, title: 'Padrão' },
    { index: 1, title: '' },
    { index: 2, title: '' },
    { index: 3, title: '' },
    { index: 4, title: '' }
  ]);

  useEffect(() => {
    console.log('colorCharge', colorCharge, 'layoutCharge', layoutCharge, 'logoCharge', logoCharge);
  }, [colorCharge, layoutCharge, logoCharge]);

  const [nextPage, setNextPage] = useState(false);
  const [errorLogo, setErrorLogo] = useState(false);

  const NextPage = () => {
    if (logoCharge !== '') {
      setNextPage(true);
    }
    else {
      setErrorLogo(true);
    }
  }

  useEffect(() => {
    if(logoCharge !== '') {
      setErrorLogo(false);
    }
  }, [logoCharge]);

  if (!nextPage) {
    console.log('entrou aqui')
    return <ChooseLayout setColorCharge={setColorCharge} setLayoutCharge={setLayoutCharge} setLogoCharge={setLogoCharge} templates={templates} NextPage={NextPage} setErrorLogo={setErrorLogo} errorLogo={errorLogo} />;
  }

  // Show the loading screen
  if (showLoading) {
    return <Loading open={true} />;
  }


  return (
    <>
      < HeaderPage title="Adicionar Planilhas" />


      <Grid container spacing={4} sx={{ padding: 5 }}>
        {renderTable ? (
          <Grid item xs={12}>
            <DynamicTableCharge data={items} dataR={dataR} setSubmitTable={setSubmitTable} handleSubmit={handleSubmit} itemsFiltered={itemsFiltered} setItemsFiltered={setItemsFiltered} setRenderTable={setRenderTable} loading={loading} />
          </Grid>
        ) : (
          <>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6} sx={{ display: "flex", flexDirection: "column" }}>
              <HelpAdd Steps={steps} Title="Gere sua cobrança" />
              {(copyRespostas.nameSheets === "" ||
                copyRespostas.descriptionSheets === "" ||
                copyRespostas.formatSheets === "" ||
                copyRespostas.linkSheets === "") && (
                  <AlertSnackbar message="Preencha todos os campos obrigatórios" severity="error" open={true} />
                )}
              {!fieldChannelSelected && dataR.length > 0 && requiredFields > 0 && (
                <AlertSnackbar message="WhatsApp ou Email não selecionado" severity="warning" open={true} />
              )}
              {erroLoading  && requiredFields > 0 && (
                <AlertSnackbar message="Lembre-se de compartilhar a planilha com o e-mail:<br>planilhas-n8n@projeto-brio-n8n.iam.gserviceaccount.com" severity="error" open={true} />
              )}
              {!fieldCPFORCNPJ && dataR.length > 0  && requiredFields > 0 && (
                <AlertSnackbar message="CPF/CNPJ não selecionado" severity="error" open={true} />
              )}
              {!fieldTotalAmount && dataR.length > 0 && requiredFields > 0 && (
                <AlertSnackbar message="Valor Total não selecionado" severity="error" open={true} />
              )}
              {!fieldNomeORFullNome && dataR.length > 0  && requiredFields > 0 && (
                <AlertSnackbar message="Nome Completo não selecionado" severity="error" open={true} />
              )}
              {
                !fieldDueDate && dataR.length > 0 && requiredFields > 0 && (
                  <AlertSnackbar message="Data de vencimento não selecionada" severity="error" open={true} />
                )
              }
              {verifyEmptyVariables && dataR.length > 0 && requiredFields > 0 && (
                <AlertSnackbar message={`Existem ${countEmptyVariables} campos sem seleção`} severity="warning" open={true} />
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                aria-label="Form and Standardization Tabs"
                style={{ background: '#FFF', borderRadius: '12px', marginBottom: '10px' }}
              >
                <Tab label="Formulário" />
                <Tab
                  label="Padronização"
                  disabled={
                    !copyRespostas.nameSheets ||
                    !copyRespostas.descriptionSheets ||
                    !copyRespostas.formatSheets ||
                    !copyRespostas.linkSheets ||
                    erroLoading
                  }
                />
              </Tabs>
              <TabPanel value={tabValue} index={0} style={{ background: '#FFF', borderRadius: '12px' }}>
                <SheetsForm
                  setDataR={setDataR}
                  copyRespostas={copyRespostas}
                  setCopyRespostas={setCopyRespostas}
                  setErrors={setErrors}
                  errors={errors}
                  setTabValue={setTabValue}
                  setErroLoading={setErroLoading}
                  setItems={setItems}
                />
              </TabPanel>
              <TabPanel value={tabValue} index={1} style={{ background: '#FFF', borderRadius: '12px' }}>
                <DataDisplayTable
                  data={dataR}
                  setData={setDataR}
                  handleSubmit={preSubmit}
                  variablesOfTemplate={variablesOfTemplate}
                  page={padronizationPage}
                  setPage={setPadronizationPage}
                />
              </TabPanel>
            </Grid>
          </>
        )}
      </Grid>

      <ModalR
        cancelText={requiredFields > 0 ?
          "Voltar" : "Mudei de ideia"}
        color={ThemeColors.purple500}
        confirmText={requiredFields > 0 ? "" : "Sim, tenho certeza"}
        subTitle={requiredFields > 0 ? "Os campos obrigatórios não foram preenchidos"
          : "Você deseja prosseguir com a importação da planilha?"}
        isOpen={openModalConfirm}
        onClose={handleCloseModalConfirm}
        onConfirm={handleSubmit}
        title={FullField ? "Alguns campos são obrigatórios"
          : verifyEmptyVariables ? `Existem ${countEmptyVariables} campos sem seleção` : "Prosseguir com a importação?"}
        emoji={verifyEmptyVariables ? "emoji04" : "emoji01"}
        iconColor={ThemeColors.purple400}
      />


    </>
  );
}

export default PageAddSheets;