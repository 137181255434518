// Component that show the user his notifications on desktop
// Updated: 09/12/2024
// Author: Simon Franklin

// React
import React, { useState } from 'react'

// Material UI Components
import { Box, Pagination } from '@mui/material';

// Components and functions for the project
import StatusIcon from '../../components/StatusLabel/StatusIcon';
import Titles from '../../components/Titles/Titles';
import Notify from '../../assets/ilustrations/Notify.gif';
import { ReadN } from '../../api/backend/Dashboard/ReadN';
import YouDontHaveNotifications from './YouDontHaveNotifications';
import NotifyModal from './NotifyModal.js'

// React
const Notifications = ({ newNotification, showAllNotifications, currentNotifications, AllNotifications, setShowAllNotifications, notificationsPerPage, setCurrentPage, setAllNotifications, setNewNotification, currentPage }) => {
    const [selectedNotification, setSelectedNotification] = useState(null);
    const [openModal, setOpenModal] = useState(false);

    const updateNotificationStatus = async (notificationId) => {
        try {
            const info = { 'notification_id': notificationId };
            const jsonData = await ReadN(info);

            if (jsonData['message'] === 'Notification read successfully') {
                setAllNotifications((prev) => prev.filter((notification) => notification.notification_id !== notificationId));
                setNewNotification((prev) => prev.filter((notification) => notification.notification_id !== notificationId));
            }
        } catch (error) {
            console.error('Erro ao atualizar o status da notificação:', error);
        }
    };

    const handleChangePage = (event, value) => {
        setCurrentPage(value);
    };

    return (
        <>
            <NotifyModal open={openModal} closeModal={() => setOpenModal(false)} notification={selectedNotification} updateNotificationStatus={updateNotificationStatus} setSelectedNotification={setSelectedNotification} />
            <Box
                flex={1}
                borderRadius={'12px'}
                border={'1px solid #D9D9DC'}
                padding={'30px'}
                sx={{
                    background: '#fff',
                    display: { xs: 'none', md: 'flex' },
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    gap: '25px',
                    minHeight: { xl: '312px', lg: '312px', sm: '290px' }
                }}
            >
                {(newNotification && newNotification.length > 0) || showAllNotifications ? (
                    <Titles children={'Notificações'} color={'#8033F4'} bold type={'H3'} />
                ) : null}
                <Box display="flex" flexDirection="column" width="100%" height="90%" alignItems="center">
                    {newNotification && newNotification.length > 0 && showAllNotifications === false ? (
                        currentNotifications.map((notification) => (
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                padding="0 10px"
                                key={notification.notification_id}
                                sx={{
                                    background: notification.backgroundColor,
                                    height: '32px',
                                    borderRadius: '6px',
                                    width: '100%',
                                    marginBottom: '10px'
                                }}
                            >
                                <Box onClick={() => (setSelectedNotification(notification), setOpenModal(true))} sx={{ cursor: 'pointer' }} display="flex" gap="7px" alignItems="center" flex="1">
                                    <StatusIcon status="Alert" color={notification.iconColor} width="12px" height="12px" />
                                    <Titles children={notification.date + ' - ' + notification.text} color={notification.textColor} />
                                </Box>
                                <Box
                                    sx={{ cursor: 'pointer' }}
                                    onClick={() => updateNotificationStatus(notification.notification_id)}
                                >
                                    <StatusIcon status="Check" color={notification.iconColor} width="12px" height="12px" />
                                </Box>
                            </Box>
                        ))
                    ) : (
                        <>
                            {AllNotifications && AllNotifications.length > 0 && !showAllNotifications ? (
                                <Box display="flex" sx={{ justifyContent: "center", alignItems: "center", width: "100%", height: "90%" }}>
                                    <Box display="flex" sx={{ alignItems: "center", justifyContent: "space-between", width: "80%" }}>
                                        <Box display={"flex"} sx={{ flexDirection: "column", gap: "10px", textAlign: "left" }}>
                                            <Titles bold type={"H3"} children={"Não há novas notificações"} color={"#52525B"} />
                                            <Titles type={"P1"} children={"Deseja visualizar as notificações antigas?"} color={"#52525B"} />
                                            <Box
                                                component="a"
                                                href="#"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setShowAllNotifications(true);
                                                }}
                                                sx={{ textDecoration: "none" }}
                                            >
                                                <Titles type={"P1"} children={"Clique aqui"} color={"#8142FC"} />
                                            </Box>
                                        </Box>
                                        <img src={Notify} alt="Você não notificações" style={{ width: "30%", height: "30%" }} />
                                    </Box>
                                </Box>
                            ) : (
                                showAllNotifications ? (
                                    currentNotifications.map((notification) => (
                                        <Box
                                            display="flex"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            padding="0 10px"
                                            key={notification.notification_id}
                                            sx={{
                                                background: notification.backgroundColor,
                                                height: '32px',
                                                borderRadius: '6px',
                                                width: '100%',
                                                marginBottom: '10px'
                                            }}
                                        >
                                            <Box onClick={() => (setSelectedNotification(notification), setOpenModal(true))} sx={{ cursor: 'pointer' }} display="flex" gap="7px" alignItems="center" flex="1">
                                                <StatusIcon status="Alert" color={notification.iconColor} width="12px" height="12px" />
                                                <Titles children={notification.date + ' - ' + notification.text} color={notification.textColor} />
                                            </Box>
                                        </Box>
                                    ))
                                ) : (
                                    <YouDontHaveNotifications />
                                )
                            )}
                        </>
                    )}
                    {AllNotifications.length > notificationsPerPage && (
                        <Box
                            display="flex"
                            justifyContent="flex-end"
                            sx={{ mt: 'auto', width: '100%' }}
                        >
                            <Pagination
                                count={Math.ceil(newNotification.length / notificationsPerPage)}
                                page={currentPage}
                                onChange={handleChangePage}
                                variant='outlined'
                                shape="rounded"
                                sx={{ alignSelf: 'flex-end' }}
                            />
                        </Box>
                    )}
                </Box>
            </Box>
        </>
    )
}

export default Notifications