// Desc: Formulário de Email para criação de novos modelos de email e também para visualização e edição de modelos de email já existentes.
// Update: 21/05/2024 (OK)

// React Components
import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';

// Material UI Components
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MenuItem, Grid, FormControl, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import ThemeColors from '../../components/ThemeColors/ThemeColors';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import TemplateInput from '../../components/Input/TemplateInput';


// render the form of SMS
const FormSMS = ({ messageTemporary, setMessageTemporary, viewWhatsAppScreenProps, variablesOfTemplate, setViewWhatsAppScreenProps, saveTemplate, edit = false }) => {

    const [imageBase64, setImageBase64] = useState('');

    useEffect(() => {
        if (imageBase64) {
            setViewWhatsAppScreenProps((prevProps) => {
                const newMessage = {
                    id: prevProps.messages.messages.length + 1,
                    type: 'img',
                    message: `${imageBase64}`,
                    fromMe: true
                };

                return {
                    ...prevProps,
                    messages: {
                        ...prevProps.messages,
                        messages: [...prevProps.messages.messages, newMessage]
                    }
                };
            });
        }
    }, [imageBase64, setViewWhatsAppScreenProps]);

    //  Function to add a message
    function addMessage() {
        setViewWhatsAppScreenProps((prevProps) => {
            const newMessage = {
                id: prevProps.messages.messages.length + 1,
                type: 'SMS',
                message: messageTemporary,
                fromMe: true
            };

            return {
                ...prevProps,
                messages: {
                    ...prevProps.messages,
                    messages: [...prevProps.messages.messages, newMessage]
                }
            };
        });

        setMessageTemporary('');
    }

    const handleDrop = (acceptedFiles) => {
        const file = acceptedFiles[0];
        const reader = new FileReader();
        reader.onload = () => {
            setImageBase64(reader.result);
        };
        reader.readAsDataURL(file);
    };

    return (
        <>
            <Grid container spacing={2} style={{ marginTop: '20px' }}>
                <TemplateInput handleChange={setMessageTemporary} type={'sms'} value={messageTemporary} variablesOfTemplate={variablesOfTemplate} />
               
                <Grid item xs={6}>
                   
                </Grid>

                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <Button style={{ width: '100%', padding: '13px' }} variant="contained"
                            onClick={saveTemplate}
                        >
                            { edit ? "Editar Modelo" : "Salvar Modelo" }
                        </Button>
                    </FormControl>
                </Grid>
            </Grid >
        </>
    );
};

export default FormSMS;