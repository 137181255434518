// Description: Modal to show the individual information of one notification
// Updated: 09/12/2024
// Author: Simon Franklin

// React
import React from 'react'

// Material UI Components
import { Box, Typography } from '@mui/material'

// Components and functions for the project
import { formatNotifyDate } from '../../utils/utils'
import StatusIcon from '../../components/StatusLabel/StatusIcon'
import Alert from '../../assets/Icons/components/Alert'

// Render the component
const NotifyModal = ({ open, closeModal, notification, updateNotificationStatus, setSelectedNotification }) => {
    return (
        <>
            {notification ? (
                <>
                    <Box onClick={closeModal} sx={{ position: 'fixed', minWidth: '100vw', minHeight: '100vh', backgroundColor: '#000', opacity: '0.4', zIndex: '20', display: open ? 'block' : 'none', top: '0', left: '0' }}></Box>
                    <Box sx={{ display: open ? 'block' : 'none', boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.25)', backgroundColor: '#FFF', borderRadius: '20px', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '20', padding: '25px', height: '600px', width: '500px' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', minWidth: '100%', gap: '25px', minHeight: '100%' }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Box onClick={() => (setSelectedNotification(null), closeModal)} sx={{ fontSize: '16px', fontWeight: '600', color: '#848484', cursor: 'pointer' }}>
                                    <StatusIcon status="Close" color={"#848484"} width="25px" height="25px" />
                                </Box>
                            </Box>

                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', flex: 1 }}>

                                <div style={{ display: 'flex', gap: '.3rem', alignItems: 'center' }}>
                                    <Alert color={notification.iconColor} />
                                    <Typography children='Equipe 123zap' sx={{ marginLeft: '20px', fontSize: '20px', fontWeight: '600' }} />
                                    <Typography children={`- ${formatNotifyDate(notification.date)}`} sx={{ fontSize: '20px', fontWeight: '500', color: '#848484', textAlign: 'justify' }} />
                                </div>
                                <Typography children={notification.text} sx={{ fontSize: '16px', fontWeight: '500', color: '#848484', flex: '1', overflowY: 'auto' }} />
                            </Box>



                            <Box onClick={notification.read ? null : () => updateNotificationStatus(notification.notification_id)} sx={{ cursor: 'pointer', transition: '.3s ease-in-out', background: '#A986FF', padding: '5px 15px', borderRadius: '8px', display: 'flex', justifyContent: 'center', justifySelf: 'flex-end', gap: '.5rem', color: !notification.read ? '#FFF' : '#000' }}>
                                {!notification.read ? (<>{'Marcar como lida'}</>) : (<>{'Lida'}</>)}
                                <StatusIcon status="Check" color={!notification.read ? '#FFF' : '#000'} width="25px" height="25px" />
                            </Box>
                        </Box>
                    </Box>
                </>
            ) : null
            }
        </>
    )
}

export default NotifyModal