// Description: Render an input for creating email
// Updated: 18/11/2024
// Author: Simon Franklin

// React
import React, { useEffect, useRef, useState } from "react";

// Material UI Components
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionSummary, AccordionDetails, Grid, Typography, MenuItem, FormControl } from "@mui/material";

// Components and functions for the project
import themeColors from "../ThemeColors/ThemeColors";
import { updatedField } from "../../utils/utils";

// Render the Component
const TemplateInput = ({ value, handleChange, type, title, variablesOfTemplate }) => {
    // State variables
    const [formattedText, setFormattedText] = useState("");
    const [isFocused, setIsFocused] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const editorRef = useRef(null);

    const templateInputStyle = {
        background: "white",
        color: "black",
        borderRadius: "12px",
        margin: 0,
        border: `1px solid ${themeColors.gray20}`,
        color: themeColors.gray40,
        height: type === "whatsapp" ? "309px" : "401px",
        width: "100%",
        padding: "14px",
        outline: "none",
        whiteSpace: "pre-wrap",
        overflowY: "auto"
    }    

    useEffect(() => {
        setFormattedText(updatedField(value, variablesOfTemplate));
    }, [value]);

    useEffect(() => {
        if (isFocused && editorRef.current) {
            const range = document.createRange();
            const sel = window.getSelection();
            range.selectNodeContents(editorRef.current);
            range.collapse(false);
            sel.removeAllRanges();
            sel.addRange(range);
        }
    }, [formattedText, isFocused]);

    // Function to toggle the visibility of the variables
    const toggleAccordionVisibility = () => {
        setIsVisible(!isVisible);
    };

    // Function to handle the change of the accordion
    const handleChangeAccordion = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    // Function to add a variable in the temporary message
    const addVariableInTemporaryMessage = (variable) => {
        handleChange(value + ' {{' + variable + '}}');
    };

    const closeAccordion = () => {
        setExpanded(false);
    };

    return (

        <>
            <Grid item xs={6}>
                <Typography style={{ color: themeColors.gray40, fontSize: '20px' }}>Mensagem</Typography>
            </Grid>
            <Grid item xs={6} style={{ position: 'relative' }}>
                <FormControl fullWidth>
                    <Typography onClick={toggleAccordionVisibility} style={{
                        color: themeColors.purple500,
                        marginRight: '5px',
                        marginTop: '2px',
                        fontFamily: 'Satoshi-Medium',
                        fontSize: '18px',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        cursor: 'pointer'
                    }}>
                        {isVisible ? "Esconder Variáveis" : "+ Adicionar Variáveis"}
                    </Typography>
                    {isVisible && (
                        <div style={{
                            position: 'absolute',
                            right: 0,
                            top: '100%',
                            width: 'auto',
                            backgroundColor: themeColors.purple200,
                            boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                            overflow: 'hidden',
                            zIndex: 1,
                            borderRadius: '12px'
                        }}>
                            {Object.entries(variablesOfTemplate).map(([category, values]) => (
                                <Accordion key={category} expanded={expanded === category} onChange={handleChangeAccordion(category)} style={{ background: themeColors.purple200, border: 'none !important' }}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>{category}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails style={{ border: 'none !important', padding: 0 }}>
                                        {values.length > 0 ? (
                                            values.map((value) => (
                                                <MenuItem key={value} onClick={() => {
                                                    addVariableInTemporaryMessage(value);
                                                    toggleAccordionVisibility();
                                                    closeAccordion();
                                                }} style={{ color: themeColors.purple950 }}>
                                                    {value}
                                                </MenuItem>
                                            ))
                                        ) : (
                                            <Typography>Adicionar variáveis</Typography>
                                        )}
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </div>
                    )}
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <div
                        ref={editorRef}
                        contentEditable
                        className="template_input"
                        dangerouslySetInnerHTML={{ __html: formattedText }}
                        onInput={(e) => (handleChange(e.target.innerText))}
                        onFocus={() => setIsFocused(true)}
                        onBlur={() => setIsFocused(false)}
                        style={templateInputStyle}
                    />
                </FormControl>
            </Grid>
        </>

    );
};

export default TemplateInput;
