// Request to get campaigns by account uuid
// Update in 09/09/2024 ( OK )

import axios from 'axios';

export async function TemplateHidden(data) {
    try {

        const token = localStorage.getItem('jwt_token');

        const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/update/template/visibility`,
            data,
            { 
                headers: { 
                    Authorization: `Bearer ${token}`,  
                },
            }
        );
        
        const responseData = response.data;
        return responseData;
    } catch (error) {
        console.error('Erro ao buscar dados da API:', error);
        const errorMsg = error.response?.data?.message || 'Erro desconhecido ao buscar dados da API';
        throw new Error(`Erro ao buscar dados da API: ${errorMsg}`);
    }
}
