// React
import React, { useState } from "react";

// Material UI Components
import { Box, TextField, Typography } from "@mui/material";

// Components and functions for the project
import { steps } from "../index.js";
import Titles from "../../../components/Titles/Titles.js";
import themeColors from "../../../components/ThemeColors/ThemeColors.js";
import checkIcon from "../check-icon.png";

const Step1 = ({ message, selected, setSelected }) => {

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
            }}
        >
            <Titles
                children={steps[0].stepTitle}
                bold
                color={themeColors.purple500}
                styles={{
                    fontSize: { xs: '20px', lg: '24px' }
                }}
            />
            <Titles
                styles={{ marginBottom: "97px", fontSize: { xs: '14px', lg: '16px' } }}
                type={"P1"}
                children={"Selecione o tipo de usuário que você é para prosseguirmos."}
                color={themeColors.gray50}
            />

            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        gap: "5px",
                        alignItems: "center",
                        cursor: "pointer",
                    }}
                    onClick={() => setSelected("J")}
                >
                    <Box
                        sx={{
                            width: "20px",
                            height: "21px",
                            border:
                                selected === "J"
                                    ? "none"
                                    : `1px solid ${themeColors.gray50}`,
                            borderRadius: "2px",
                            background:
                                selected === "J"
                                    ? `${themeColors.purple500}`
                                    : "none",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <img
                            src={checkIcon}
                            alt="check"
                            style={{
                                display:
                                    selected === "J" ? "block" : "none",
                                width: "11px",
                                height: "8.9px",
                            }}
                        />
                    </Box>
                    <Titles
                        color={
                            selected === "J"
                                ? "#000"
                                : themeColors.gray50
                        }
                        children={"Empresa (Pessoa Jurídica)"}
                    />
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        gap: "5px",
                        alignItems: "center",
                        cursor: "pointer",
                    }}
                    onClick={() => setSelected("F")}
                >
                    <Box
                        sx={{
                            width: "20px",
                            height: "21px",
                            border:
                                selected === "F"
                                    ? "none"
                                    : `1px solid ${themeColors.gray50}`,
                            borderRadius: "2px",
                            background:
                                selected === "F"
                                    ? `${themeColors.purple500}`
                                    : "none",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <img
                            src={checkIcon}
                            alt="check"
                            style={{
                                display: selected === "F" ? "block" : "none",
                                width: "11px",
                                height: "8.9px",
                            }}
                        />
                    </Box>
                    <Titles
                        color={selected === "F" ? "#000" : themeColors.gray50}
                        children={"Pessoa Física"}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default Step1