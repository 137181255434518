// This component renders the Dashboard page, which is the main page of the application.
// It displays the user's information, notifications, and performance indicators.
// The user's information is displayed on the left side of the page, while the notifications and performance indicators are displayed on the right side.
// The notifications are displayed in a list format, and the user can view all notifications or only the new ones.
// The performance indicators are displayed in a chart format, showing the user's performance over time.
// Author: Vitor Jesus

// Documentation: https://briotecnologia.atlassian.net/wiki/spaces/DI1/pages/101416971/Dashboard
// Prototype: https://www.figma.com/design/NqSBP9Hjy6KFGUik7FKy4F/UIUX-design?node-id=986-58&t=mEOidrARSPxeHbDw-4

import React, { Fragment, useEffect, useState } from 'react';

import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import Box from '@mui/material/Box';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import Fade from '@mui/material/Fade';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';

import AlternativeAvatar from '../../assets/images/avatar-padrao.png';
import { DashboardData } from '../../api/backend/Dashboard/DashboardData';
import { isAuthenticated } from '../../authMiddleware';
import Loading from '../../components/Loading/Loading';
import PerformanceIndicator from '../../components/Performance/PerformanceIndicator';
import Welcome from './Welcome';
import { colorMapping } from '../../utils/utils';
import Notifications from './Notifications';

function DashboardPage() {

    const { authenticated } = isAuthenticated();
    const [userInfo, setUserInfo] = useState({});

    const [dataFromAPI, setDataFromAPI] = useState(null);
    const [loading, setLoading] = useState(true);
    const [userAvatar, setUserAvatar] = useState('');

    const [AllNotifications, setAllNotifications] = useState([]);
    const [newNotification, setNewNotification] = useState([]);
    const [showAllNotifications, setShowAllNotifications] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const notificationsPerPage = 4;

    useEffect(() => {
        if (showAllNotifications) {
            setNewNotification(AllNotifications);
        }
    }, [showAllNotifications]);
    
    useEffect(() => {
        if (authenticated) {
            const { decryptedData } = isAuthenticated();
            setUserInfo(decryptedData);
        }
    }, []);

    useEffect(() => {
        if (userInfo['UUID']) {
            const userHash = userInfo['UUID'] + '.png';
            fetchUserAvatar(userHash);
        }
    }, [userInfo]);

    const [mockup, setMockupData] = useState({
        'labels': ['Enviadas', 'Visualizadas', 'Recebidas', 'Geral', 'Erro'],
        'datasets': {
            'Enviadas': {
                'quantidade': 0,
                'icone': SendOutlinedIcon
            },
            'Visualizadas': {
                'quantidade': 0,
                'icone': TaskAltOutlinedIcon
            },

            'Recebidas': {
                'quantidade': 0,
                'icone': AccessTimeOutlinedIcon
            },
            'Erro': {
                'quantidade': 0,
                'icone': ErrorOutlineOutlinedIcon
            },
            'Geral': {
                'quantidade': 0,
                'icone': FactCheckOutlinedIcon
            },
        }
    });

    useEffect(() => {
        async function fetchDataWrapper() {
            if (userInfo && userInfo['UUID']) {
                
                try {
                    
                    const data = await DashboardData();

                    const jsonData = data.data;
        
                    const mappedNotifications = jsonData['notifications'].map((notification) => ({
                        notification_id: notification.id,
                        text: notification.text,
                        ...colorMapping[notification.colormapping],
                        date: notification.timestamp.split(' ')[0],
                    }));

                    setAllNotifications(mappedNotifications);

                    const mappedNewNotifications = jsonData['notifications']
                        .filter((notification) => !notification.read)
                        .map((notification) => ({
                            notification_id: notification.id,
                            text: notification.text,
                            ...colorMapping[notification.colormapping],
                            date: notification.timestamp.split(' ')[0],
                        }));

                    setNewNotification(mappedNewNotifications);

                    setDataFromAPI(jsonData);
                    setMockupData(mockup);

                } catch (error) {

                } finally {
                    setLoading(false);
                }
            }
        }
        fetchDataWrapper();
    }, [userInfo]);

    useEffect(() => {
        if (dataFromAPI) {

            try {
                const { datasets = [] } = dataFromAPI;

                const monthlyData = datasets.reduce((acc, curr) => {
                    const monthYear = curr['month-year'];
                    if (!acc[monthYear]) {
                        acc[monthYear] = { send: 0, read: 0, error: 0, total: 0, received: 0 };
                    }
                    acc[monthYear].send += curr.send;
                    acc[monthYear].read += curr.read;
                    acc[monthYear].error += curr.error;
                    acc[monthYear].total += curr.total;
                    acc[monthYear].received += curr.received;
                    return acc;
                }, {});

                const labels = Object.keys(monthlyData);
                const readTotals = labels.map(monthYear => monthlyData[monthYear].read);
                const errorTotals = labels.map(monthYear => monthlyData[monthYear].error);
                const totalTotals = labels.map(monthYear => monthlyData[monthYear].total);
                const sentAndReadTotals = labels.map(monthYear => monthlyData[monthYear].send + monthlyData[monthYear].read);
                const receivedTotals = labels.map(monthYear => monthlyData[monthYear].received);

                setMockupData(prevMockup => ({
                    ...prevMockup,
                    labels: labels,
                    datasets: {
                        ...prevMockup.datasets,
                        Enviadas: {
                            ...prevMockup.datasets['Enviadas'],
                            quantidade: sentAndReadTotals
                        },
                        Visualizadas: {
                            ...prevMockup.datasets['Visualizadas'],
                            quantidade: readTotals
                        },
                        Erro: {
                            ...prevMockup.datasets['Erro'],
                            quantidade: errorTotals
                        },
                        Geral: {
                            ...prevMockup.datasets['Geral'],
                            quantidade: totalTotals
                        },
                        Recebidas: {
                            ...prevMockup.datasets['Recebidas'],
                            quantidade: receivedTotals
                        }
                    }
                }));

            }
            catch (error) {
                console.error('Erro ao atualizar o mockup:', error);
            } finally {
                setLoading(false);
            }
        }
    }, [dataFromAPI]);

    const fetchUserAvatar = async (userHash) => {
        try {
            const imageUrl = 'https://files123zap.s3.amazonaws.com/avatar_users/' + userHash;
            const img = new Image();
            img.onload = () => {
                setUserAvatar(imageUrl);
            };
            img.onerror = () => {
                console.error('Erro ao carregar a imagem:', imageUrl);
                setUserAvatar(AlternativeAvatar);
            };
            img.src = imageUrl;
        } catch (error) {
            console.error('Erro ao buscar o avatar do usuário:', error);
            setUserAvatar(AlternativeAvatar);
        }
    };

    const indexOfLastNotification = currentPage * notificationsPerPage;
    const indexOfFirstNotification = notificationsPerPage * (currentPage - 1);
    const currentNotifications = !showAllNotifications
        ? newNotification.slice(indexOfFirstNotification, indexOfLastNotification)
        : AllNotifications.slice(indexOfFirstNotification, indexOfLastNotification);

    if (loading) {
        <Loading open={loading} />
    }

    return (
        <Fragment>
            {!loading && (
                <Fade in={!loading}>
                    <Box display={'flex'} flexDirection={'column'} minHeight={'100vh'} width={"100%"}>
                        <Box display={'flex'} gap={3} padding={3} paddingLeft={'20px'} paddingRight={'20px'} paddingTop={'20px'} sx={{ flexDirection: { xl: 'row', lg: 'row', sm: 'column' } }}>
                            <Welcome userInfo={userInfo} dataFromAPI={dataFromAPI} userAvatar={userAvatar} />
                            <Notifications AllNotifications={AllNotifications} currentNotifications={currentNotifications} newNotification={newNotification} setShowAllNotifications={setShowAllNotifications} showAllNotifications={showAllNotifications} notificationsPerPage={notificationsPerPage} setAllNotifications={setAllNotifications} setCurrentPage={setCurrentPage} setNewNotification={setNewNotification} currentPage={currentPage} />
                        </Box>
                        <PerformanceIndicator mockup={mockup} dataFromAPI={dataFromAPI} />
                    </Box>
                </Fade>
            )}
            <Loading open={loading} />
        </Fragment>
    );
}

export default DashboardPage;