import React from 'react'

const Send = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22 2L11 13" stroke="#52525B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M22 2L15 22L11 13L2 9L22 2Z" stroke="#52525B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default Send