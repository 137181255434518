// This component is responsible for rendering the reports page of the dashboard
// The client can see the general analysis of the sentiments of the messages sent by the customers
// The client can download the report of the analysis of the messages sent by the customers
// The client can see the total of messages analyzed by the system
// Author: Vitor de Jesus

// Documentation: https://briotecnologia.atlassian.net/wiki/spaces/DI1/pages/134512643/Estrutura
// Prototype: https://www.figma.com/design/NqSBP9Hjy6KFGUik7FKy4F/UIUX-design?node-id=2138-436&t=Wrecb0FHQVrbZ5lv-4

import React, { Fragment, useEffect, useState } from 'react';

import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import Box from '@mui/material/Box';
import BorderAllIcon from '@mui/icons-material/BorderAll';
import { Button, useMediaQuery } from '@mui/material';
import { DashboardData } from '../../api/backend/Dashboard/DashboardData';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import Fade from '@mui/material/Fade';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import Typography from '@mui/material/Typography';

import AlternativeAvatar from '../../assets/images/avatar-padrao.png';
import { Analytics } from '../../api/backend/Dashboard/ExportAnalytics';
import Badge from '../../assets/images/robo.png';
import ConfigIcon from '../../assets/images/config.png';
import { isAuthenticated } from '../../authMiddleware';
import Loading from '../../components/Loading/Loading';
import PerformanceIndicator from '../../components/Performance/PerformanceIndicator';
import StatusIcon from '../../components/StatusLabel/StatusIcon';
import ThemeColors from '../../components/ThemeColors/ThemeColors';
import Titles from '../../components/Titles/Titles';
import { ReactComponent as TagIcon } from '../../assets/Icons/svg/TagIcon.svg';
import { ReactComponent as CampaignIcon } from '../../assets/Icons/svg/CampaignIcon.svg';

function DashboardPage() {

    const { authenticated } = isAuthenticated();
    const [userInfo, setUserInfo] = useState({});
    const [dataFromAPI, setDataFromAPI] = useState(null);
    const [loading, setLoading] = useState(true);
    const [entidades, setEntidades] = useState([]);
    const [frasesChave, setFrasesChave] = useState([]);
    const isSmallScreen = useMediaQuery('(max-width: 600px)');

    const colorMappingSentiments = {

        'Positive': {
            iconColor: '#4C8435',
            backgroundColor: '#D3EAC8',
            textColor: '#345427',
        },
        'Negative': {
            iconColor: '#CD4747',
            backgroundColor: ThemeColors.red01,
            textColor: '#4E1B1D',
        },
        'Neutral': {
            iconColor: '#8142FC',
            backgroundColor: '#DDD4FF',
            textColor: '#4F189A',
        },
        'Mixed': {
            iconColor: '#848484',
            backgroundColor: '#E5E5E5',
            textColor: '#303035',
        },
    };

    useEffect(() => {
        if (authenticated) {
            const { decryptedData } = isAuthenticated();
            setUserInfo(decryptedData);
        }
    }, []);

    const [mockup, setMockupData] = useState({
        'labels': ['Enviadas', 'Visualizadas', 'Recebidas', 'Geral', 'Erro'],
        'datasets': {
            'Enviadas': {
                'quantidade': 0,
                'icone': SendOutlinedIcon
            },
            'Visualizadas': {
                'quantidade': 0,
                'icone': TaskAltOutlinedIcon
            },

            'Recebidas': {
                'quantidade': 0,
                'icone': AccessTimeOutlinedIcon
            },
            'Erro': {
                'quantidade': 0,
                'icone': ErrorOutlineOutlinedIcon
            },
            'Geral': {
                'quantidade': 0,
                'icone': FactCheckOutlinedIcon
            },
        }
    });

    useEffect(() => {
        async function fetchDataWrapper() {
            if (userInfo && userInfo['UUID']) {
                try {

                    const data = await DashboardData();
                    const resultJson = data.data;
                    setDataFromAPI(resultJson);
                    setMockupData(mockup);
                    setDataSimulation(resultJson['analytics']);
                    const entidades = resultJson['analytics']['Entidades'];
                    const frasesChave = resultJson['analytics']['Frases-Chave'];
                    const entidadesFiltradas = entidades.flatMap((entidadeArray) =>
                        entidadeArray
                            .filter((item) => item.Text && item.Type && item.Score) // Filtrar apenas itens com todos os campos
                            .map((item) => ({
                                'icon': <StatusIcon status={'Alert'} />,
                                'Text': item.Text,
                                'Type': item.Type,
                                'Score': item.Score
                            }))
                    );
                    const frasesChaveFiltradas = frasesChave.flatMap((fraseArray) =>
                        fraseArray
                            .filter((item) => item.Text && item.Score) // Filtrar apenas itens com todos os campos
                            .map((item) => ({
                                'Text': item.Text,
                                'Score': item.Score
                            }))
                    );
                    setEntidades(entidadesFiltradas);
                    setFrasesChave(frasesChaveFiltradas);
                } catch (error) {
                    console.error('Erro ao buscar os dados da API:', error);
                }
            }
        }
        fetchDataWrapper();
    }, [userInfo]);

    const downloadReport = async () => {
        if (userInfo && userInfo['UUID']) {
            try {
                const blob = await Analytics((percentCompleted) => {
                    console.log(`Progresso do download: ${percentCompleted}%`);
                });
                    const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'analytics_report.xlsx';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
            } catch (error) {
                console.error('Erro ao fazer o download do relatório:', error);
            }
        }
    };
    
    useEffect(() => {
        if (dataFromAPI) {

            try {

                const { datasets = [] } = dataFromAPI;

                const monthlyData = datasets.reduce((acc, curr) => {
                    const monthYear = curr['month-year'];
                    if (!acc[monthYear]) {
                        acc[monthYear] = { send: 0, read: 0, error: 0, total: 0, received: 0 };
                    }
                    acc[monthYear].send += curr.send;
                    acc[monthYear].read += curr.read;
                    acc[monthYear].error += curr.error;
                    acc[monthYear].total += curr.total;
                    acc[monthYear].received += curr.received;
                    return acc;
                }, {});

                const labels = Object.keys(monthlyData);
                const readTotals = labels.map(monthYear => monthlyData[monthYear].read);
                const errorTotals = labels.map(monthYear => monthlyData[monthYear].error);
                const totalTotals = labels.map(monthYear => monthlyData[monthYear].total);
                const sentAndReadTotals = labels.map(monthYear => monthlyData[monthYear].send + monthlyData[monthYear].read);
                const receivedTotals = labels.map(monthYear => monthlyData[monthYear].received);

                setMockupData(prevMockup => ({
                    ...prevMockup,
                    labels: labels,
                    datasets: {
                        ...prevMockup.datasets,
                        Enviadas: {
                            ...prevMockup.datasets['Enviadas'],
                            quantidade: sentAndReadTotals
                        },
                        Visualizadas: {
                            ...prevMockup.datasets['Visualizadas'],
                            quantidade: readTotals
                        },
                        Erro: {
                            ...prevMockup.datasets['Erro'],
                            quantidade: errorTotals
                        },
                        Geral: {
                            ...prevMockup.datasets['Geral'],
                            quantidade: totalTotals
                        },
                        Recebidas: {
                            ...prevMockup.datasets['Recebidas'],
                            quantidade: receivedTotals
                        }
                    }
                }));
            }
            catch (error) {
                console.error('Erro ao atualizar o mockup:', error);
            } finally {
                setLoading(false);
            }
        }
    }, [dataFromAPI]);

    const [dataSimulation, setDataSimulation] = useState();

    if (loading) {
        <Loading open={loading} />
    }

    return (
        <Fragment>
            {!loading && (
                <Fade in={!loading}>
                    <Box display={'flex'} flexDirection={'column'} minHeight={'100vh'} width={"100%"}>
                        <Box display={'flex'} gap={3} padding={3} paddingLeft={'20px'} paddingRight={'20px'} paddingTop={'20px'} sx={{ flexDirection: { xl: 'row', lg: 'row', xs: 'column' } }}>
                            <Box flex={1} display={'flex'} flexDirection={'column'} borderRadius={'12px'} overflow={'hidden'} sx={{ minHeight: { xl: '312px', lg: '312px', sm: '290px' } }}>
                                <Box
                                    sx={{
                                        background: '#8142FC',
                                        flex: 1,
                                        display: 'flex',
                                        padding: { xs: '18px', sm: '30px' },
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Box display={'flex'} justifyContent={'space-between'} minWidth={'100%'}>
                                        <Box display={'flex'} gap={'15px'} marginBottom={isSmallScreen ? '15px' : '0'}>
                                            <Box display={'flex'} flexDirection={'column'} justifyContent={'space-between'} maxHeight={'71px'}>
                                                <Titles children={'Pronto para os Insights?'} styles={{ fontSize: { sm: '30px', xs: '20px' } }} color={'#F5F3FF'} />
                                            </Box>
                                        </Box>
                                        <Box
                                            component={'img'}
                                            sx={{
                                                cursor: 'pointer',
                                                transition: '.3s',
                                                width: '19.17px',
                                                height: '18.84px',
                                                '&:hover': {
                                                    scale: '1.1'
                                                }
                                            }}
                                            src={ConfigIcon}
                                            alt='Configuration'
                                        />
                                    </Box>
                                    <Box display={'flex'} justifyContent={'space-between'} gap={'15px'} marginBottom={isSmallScreen ? '15px' : '0'}>
                                        <Box
                                            sx={{
                                                background: '#8033F4',
                                                color: '#EDE8FF',
                                                borderRadius: '6px',
                                                height: '33px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '7px',
                                                flex: '1',
                                                padding: '0 0 0 10px',
                                            }}
                                        >
                                            <TagIcon width={isSmallScreen ? '14px' : '18px'} height={isSmallScreen ? '14px' : '18px'} fill={'#EDE8FF'} />
                                            <Titles color={'#EDE8FF'} children={`Canais abertos`} styles={{ fontSize: { xl: '16px', lg: '12px', md: '16px', sm: '16px', xs: '11px' } }} />
                                            <Titles color={'#EDE8FF'} children={`${dataFromAPI && dataFromAPI['total_channels'] ? dataFromAPI['total_channels'].toString().padStart(2, '0') : '0'}`} styles={{ fontSize: { xl: '16px', lg: '12px', md: '16px', sm: '16px', xs: '11px' } }} />
                                        </Box>
                                        <Box
                                            sx={{
                                                background: '#8033F4',
                                                color: '#EDE8FF',
                                                borderRadius: '6px',
                                                height: '33px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '7px',
                                                flex: '1',
                                                padding: '0 0 0 10px'
                                            }}
                                        >
                                            <CampaignIcon width={isSmallScreen ? '14px' : '18px'} height={isSmallScreen ? '14px' : '18px'} fill={'#EDE8FF'} />
                                            <Titles color={'#EDE8FF'} children={`Campanhas ativas`} styles={{ fontSize: { xl: '16px', lg: '12px', md: '16px', sm: '16px', xs: '11px' } }} />
                                            <Titles color={'#EDE8FF'} children={`${dataFromAPI && dataFromAPI['total_campaigns'] ? dataFromAPI['total_campaigns'].toString().padStart(2, '0') : '0'}`} styles={{ fontSize: { xl: '16px', lg: '12px', md: '16px', sm: '16px', xs: '11px' } }} />
                                        </Box>

                                    </Box>
                                    <Box display={'flex'} justifyContent={'space-between'} gap={'15px'}>
                                        <Box
                                            sx={{
                                                background: '#8033F4',
                                                color: '#EDE8FF',
                                                borderRadius: '6px',
                                                height: '33px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '7px',
                                                flex: '1',
                                                padding: '0 0 0 10px',
                                            }}
                                        >
                                            <BorderAllIcon width={isSmallScreen ? '14px' : '18px'} height={isSmallScreen ? '14px' : '18px'} style={{ fill: '#EDE8FF' }} />
                                            <Titles color={'#EDE8FF'} children={`Planilhas armazenadas`} styles={{ fontSize: { xl: '16px', lg: '12px', md: '16px', sm: '16px', xs: '11px' } }} />
                                            <Titles color={'#EDE8FF'} children={`${dataFromAPI && dataFromAPI['total_spreadsheets'] ? dataFromAPI['total_spreadsheets'].toString().padStart(2, '0') : '0'}`} styles={{ fontSize: { xl: '16px', lg: '12px', md: '16px', sm: '16px', xs: '11px' } }} />
                                        </Box>
                                        <Box
                                            sx={{
                                                background: '#8033F4',
                                                color: '#EDE8FF',
                                                borderRadius: '6px',
                                                height: '33px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '7px',
                                                flex: '1',
                                                padding: '0 0 0 10px'
                                            }}
                                        >
                                            <AlternateEmailIcon width={isSmallScreen ? '14px' : '18px'} height={isSmallScreen ? '14px' : '18px'} style={{ fill: '#EDE8FF' }} />
                                            <Titles color={'#EDE8FF'} children={`Modelos criados`} styles={{ fontSize: { xl: '16px', lg: '12px', md: '16px', sm: '16px', xs: '11px' } }} />
                                            <Titles color={'#EDE8FF'} children={`${dataFromAPI && dataFromAPI['total_templates'] ? dataFromAPI['total_templates'].toString().padStart(2, '0') : '0'}`} styles={{ fontSize: { xl: '16px', lg: '12px', md: '16px', sm: '16px', xs: '11px' } }} />
                                        </Box>

                                    </Box>
                                </Box>

                                <Box
                                    sx={{
                                        background: '#8033F4',
                                        height: { xs: '70px', sm: '100px' },
                                        padding: { xs: '0 18px', sm: '0 30px' },
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Box display={'flex'} gap={'10px'}>
                                        <Box
                                            component={'img'}
                                            sx={{
                                                width: { xs: '60px', sm: '100px' },
                                                height: { xs: '60px', sm: '100px' },
                                            }}
                                            alt={'Badge'}
                                            src={Badge}
                                        />
                                        <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} >
                                            <Titles color={'#F5F3FF'} children={'Inteligência Artificial'} styles={{ fontSize: { sm: '16px', xs: '13px' } }} />
                                            <Titles
                                                color={'#F5F3FF'}
                                                children={
                                                    'Zapi'
                                                }
                                                styles={{ fontSize: { xl: '24px', lg: '16px', sm: '20px', xs: '12px' } }}
                                            />
                                        </Box>
                                    </Box>
                                    <Button
                                        sx={{
                                            background: '#5F1BBC',
                                            borderRadius: '6px',
                                            padding: { xs: '6px 12px', sm: '12px 24px' },
                                            color: '#DDD4FF',
                                            fontSize: { xs: '12px', sm: '16px' },
                                            transition: '.3s',
                                            '&:hover': {
                                                scale: '1.05',
                                                background: '#5F1BBC',
                                            }
                                        }}

                                        onClick={() => downloadReport()}
                                    >
                                        Baixar relatório
                                    </Button>
                                </Box>
                            </Box>
                            <Box
                                flex={1}
                                borderRadius={'12px'}
                                border={'1px solid #D9D9DC'}
                                padding={'30px'}
                                sx={{
                                    background: '#fff',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    gap: '25px',
                                    minHeight: { xl: '312px', lg: '312px', sm: '290px' }
                                }}
                            >
                                <Typography sx={{ color: '#8033F4', fontWeight: 'bold', fontSize: { sm: '24px', xs: '20px' } }}>
                                    Análise Geral de Sentimentos <Typography component="span" style={{ fontWeight: 'normal', fontSize: '20px' }}> ( Clientes )</Typography>
                                </Typography>
                                <Box display="flex" flexDirection="column" width="100%" height="90%" alignItems="lefts">

                                    {dataSimulation && Object.keys(dataSimulation['Sentimentos Gerais']).map((key, index) => (
                                        <Box
                                            display="flex"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            padding="0 10px"
                                            key={index}
                                            sx={{
                                                background: colorMappingSentiments[key].backgroundColor,
                                                height: '32px',
                                                borderRadius: '6px',
                                                width: `100%`,
                                                marginBottom: '10px',
                                                minWidth: '100%',
                                            }}
                                        >

                                            <Box display="flex" gap="7px" alignItems="center" flex="1">
                                                <StatusIcon status="Alert" color={colorMappingSentiments[key].iconColor} width="12px" height="12px" />
                                                <Titles children={

                                                    key === 'Mixed' ? 'Sentimentos mistos' : key === 'Neutral' ? 'Sentimentos neutros' : key === 'Positive' ? 'Sentimentos positivos' : 'Sentimentos negativos'

                                                } color={colorMappingSentiments[key].textColor} type="P2" />
                                                <Titles children={`: ${dataSimulation['Sentimentos Gerais'][key].toFixed(2)}%`} color={colorMappingSentiments[key].textColor} type="P2" />
                                            </Box>
                                        </Box>
                                    ))}
                                    <Box
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        padding="0 10px"
                                        key='total'
                                        sx={{
                                            background: ThemeColors.yellow01,
                                            height: '32px',
                                            borderRadius: '6px',
                                            width: '100%',
                                            marginBottom: '10px',
                                            minWidth: '100%',
                                        }}
                                    >

                                        <Box display="flex" gap="7px" alignItems="center" flex="1">
                                            <StatusIcon status="Alert" color='#DEA73F' width="12px" height="12px" />
                                            <Titles children='Setenças analisadas' color='#8D4D1B' type="P2" />
                                            <Titles children={dataFromAPI && dataFromAPI['analytics'] && dataFromAPI['analytics']['Total de Mensagens'] ? dataFromAPI['analytics']['Total de Mensagens'] : '0'}
                                                color='#8D4D1B' type="P2" />
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <PerformanceIndicator mockup={mockup} dataFromAPI={dataFromAPI} />
                    </Box>
                </Fade>
            )}
            <Loading open={loading} />
        </Fragment>
    );
}

export default DashboardPage;