import React from 'react'

const Support = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#52525B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z" stroke="#52525B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M4.93018 4.92969L9.17018 9.16969" stroke="#52525B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M14.8301 14.8301L19.0701 19.0701" stroke="#52525B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M14.8301 9.16969L19.0701 4.92969" stroke="#52525B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M14.8301 9.16965L18.3601 5.63965" stroke="#52525B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M4.93018 19.0701L9.17018 14.8301" stroke="#52525B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}

export default Support