// This file is responsible for the routes of the application, it is here that the routes are defined and the components are called according to the route.
// The routes are defined using the Routes and Route components from the react-router-dom library.
// The Routes component is responsible for defining the routes, and the Route component is responsible for defining a specific route.
// The element attribute of the Route component is responsible for defining the component that will be called when the route is accessed.
// The path attribute of the Route component is responsible for defining the route that will be accessed.
// Author: Vitor Jesus

// Documentation: https://briotecnologia.atlassian.net/wiki/spaces/DI1/pages/134873089/Routes.js

import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import LoginForm from './pages/Login';
import LoginRecoveryPage from './pages/Login/LoginRecoveryPage';

import DashboardPage from './pages/Dashboard';
import Reports from './pages/Dashboard/reports';

import ChannelPage from './pages/Channel';
import ChannelPageAdd from './pages/Channel/ChannelPageAdd';
import ChannelPageAddWhatsApp from './pages/Channel/ChannelPageAddWhatsApp';
import ChannelPageConfig from './pages/Channel/ChannelPageConfig';

import Erro from './pages/Error';

import Templates from './pages/Templates';
import TemplateAdd from './pages/Templates/TemplatePageAdd';
import TemplateView from './pages/Templates/TemplateViewPage';
import PageOptionsChannel from './pages/Templates/TemplatePageOptionsChannel';
import PageOptionsTemplate from './pages/Templates/TemplatePageOptions';
import PageTemplateServices from './pages/Templates/TemplatePageServices';
import PageTemplateFinanceiro from './pages/Templates/TemplatePageFinanceiro';

import Sheets from './pages/Sheets';
import PageAddSheets from './pages/Sheets/addSheets';

import CampaignPage from './pages/Campaigns';
import PageAddPersonalized from './pages/Campaigns/PageAddPersonalized';
import PageAddFast from './pages/Campaigns/PageAddFast';

import Support from './pages/Support';
import PageAddRequest from './pages/Support/AddRequest';

import Charges from './pages/Financial';
import GenerateCharge from './pages/Financial/generateCharge';
import AddCredit from './pages/Financial/addCredit';

import Styles from './components/Styles/Styles';
import TestPage from './pages/Test';
import EditTemplate from './pages/Templates/EditTemplate';
import { useAuth } from './context/AuthContext';
import { isAuthenticated } from './authMiddleware';
import TemplateEmailHTML from './pages/Templates/TemplateEmailHTML';
import EditTemplateHTML from './pages/Templates/EditTemplateHTML';
import PageTemplateMarketing from './pages/Templates/TemplatePageMarketing';
import HappyBirthdayTemplate from './pages/Templates/HtmlTemplates/HappyBirthDayTemplate';
import InvoicesTemplate from './pages/Templates/HtmlTemplates/Invoices';

import Apis from './pages/Apis';
import PageAddApis from './pages/Apis/addSheets';
import PageOptionsCampaign from './pages/Campaigns/addCampaignOptions';
import PageAddSelected from './pages/Campaigns/PageAddSelected';
import SelfRegister from './pages/SelfRegister';
import PageSendPix from './pages/Financial/sendPix';
import ChoosePortal from './pages/Financial/ChoosePortal';
import ConfigurationPage from './pages/Configuration';
import CreateEmailTemplate from './pages/Templates/CreateEmailTemplate';

function App() {

  const { userAuthenticated  } = useAuth();
  const { decryptedData } = isAuthenticated();
  const [userTypeCookie, setUserTypeCookie] = useState('');
  

  const [loading, setLoading] = useState(false);

  useEffect(() => {
      setLoading(false);
  }, [userAuthenticated]);

  useEffect(() => {
    console.log('decryptedData', decryptedData);
    if (decryptedData) {
      setUserTypeCookie(decryptedData.USERTYPE);
    }
  }, [decryptedData]);
  useEffect(() => {
    console.log('userTypeCookie', userTypeCookie);
  }, [userTypeCookie]);

  if (loading) {
    return null;
  }

  return (

    <Routes>
      <Route path="/" element={userAuthenticated ? <Navigate to="/painel" /> : <LoginForm />} />

      {!userAuthenticated && (
        <>
          <Route path='/login/recovery/:token?' element={<LoginRecoveryPage />} />
          <Route path="/styles" element={<Styles />} />
          <Route path="*" element={<Navigate to="/" />} />
          <Route path="/cadastro" element={<SelfRegister />} />
        </>
      )}

      {userAuthenticated && (
        <>
          {userAuthenticated && userTypeCookie === 'Admin' && (

            <>
              <Route path="/painel" element={<DashboardPage />} />
              <Route path="/relatorios" element={<Reports />} />
              <Route path="/apis" element={<Apis />} />
              <Route path="/apis/adicionar" element={<PageAddApis />} />
              <Route path="/canais" element={<ChannelPage />} />
              <Route path="/canais/adicionar" element={<ChannelPageAdd />} />
              <Route path="/canais/adicionar/whatsapp" element={<ChannelPageAddWhatsApp />} />
              <Route path="/canais/configurações" element={<ChannelPageConfig />} />
              <Route path="/modelos" element={<Templates />} />
              <Route path="/modelos/canal" element={<PageOptionsChannel />} />
              {/* <Route path="/modelos/canal/template" element={<PageOptionsTemplate />} />
              <Route path="/modelos/canal/template/marketing" element={<PageTemplateMarketing />} />
              <Route path="/modelos/canal/template/marketing/aniversariante" element={<HappyBirthdayTemplate />} />
              <Route path="/modelos/canal/template/serviços" element={<PageTemplateServices />} />
              <Route path="/modelos/canal/template/serviços/senha" element={<TemplateEmailHTML />} />
              <Route path="/modelos/canal/template/financeiro" element={<PageTemplateFinanceiro />} />
              <Route path="/modelos/canal/template/financeiro/lembrete" element={<InvoicesTemplate />} /> */}
              <Route path='/modelos/canal/whatsapp' element={< TemplateAdd typeChannel='WhatsApp' />} />
              {/* <Route path='/modelos/canal/email' element={< TemplateAdd typeChannel='E-mail' />} /> */}
              <Route path='/modelos/canal/email' element={<CreateEmailTemplate />} />
              <Route path='/modelos/canal/sms' element={< TemplateAdd typeChannel='SMS' />} />
              <Route path='/modelos/editar' element={<EditTemplate />} />
              <Route path='/modelos/serviços/editar' element={<EditTemplateHTML />} />
              <Route path="/modelos/serviços" element={<PageTemplateFinanceiro />} />
              <Route path="/listagens" element={<Sheets />} />
              <Route path="/listagens/adicionar" element={<PageAddSheets />} />
              <Route path="/campanhas" element={<CampaignPage />} />
              <Route path="/campanhas/adicionar" element={<PageOptionsCampaign />} />
              <Route path="/template/view" element={<TemplateView />} />
              <Route path="/suporte" element={<Support />} />
              <Route path="/suporte/adicionar" element={<PageAddRequest />} />
              <Route path="/teste" element={<TestPage />} />
              <Route path="/campanhas/adicionar/modo-personalizado" element={<PageAddPersonalized />} />
              <Route path="/campanhas/adicionar/modo-rapido" element={<PageAddFast />} />

              <Route path="/financeiro" element={<Charges />} />
              <Route path="/financeiro/gerar-cobranças" element={<GenerateCharge />} />
              <Route path="/financeiro/adicionar-recarga" element={<AddCredit />} />
              <Route path="/financeiro/enviar-pix" element={<PageSendPix />} />
              <Route path="/financeiro/configurar-portal-faturas" element={<ChoosePortal />} />

              <Route path="/campanhas/adicionar/modo-seleção" element={<PageAddSelected />} />
              <Route path="/configurações" element={<ConfigurationPage />} />

              <Route path="*" element={<Erro />} />
            </>
          )}

          {userAuthenticated && userTypeCookie === 'Client' && (
            <>
              <Route path="/painel" element={<DashboardPage />} />
              <Route path="/relatorios" element={<Reports />} />
              <Route path="/relatorios" element={<Reports />} />
              <Route path="/canais" element={<ChannelPage />} />
              <Route path="/canais/adicionar" element={<ChannelPageAdd />} />
              <Route path="/canais/adicionar/whatsapp" element={<ChannelPageAddWhatsApp />} />
              <Route path="/canais/configurações" element={<ChannelPageConfig />} />
              <Route path="/modelos" element={<Templates />} />
              <Route path="/modelos/canal" element={<PageOptionsChannel />} />
              {/* <Route path="/modelos/canal/template" element={<PageOptionsTemplate />} />
              <Route path="/modelos/canal/template/marketing" element={<PageTemplateMarketing />} />
              <Route path="/modelos/canal/template/marketing/aniversariante" element={<HappyBirthdayTemplate />} />
              <Route path="/modelos/canal/template/serviços" element={<PageTemplateServices />} />
              <Route path="/modelos/canal/template/serviços/senha" element={<TemplateEmailHTML />} />
              <Route path="/modelos/canal/template/financeiro" element={<PageTemplateFinanceiro />} />
              <Route path="/modelos/canal/template/financeiro/lembrete" element={<InvoicesTemplate />} /> */}
              <Route path='/modelos/canal/whatsapp' element={< TemplateAdd typeChannel='WhatsApp' />} />
              {/* <Route path='/modelos/canal/email' element={< TemplateAdd typeChannel='E-mail' />} /> */}
              <Route path='/modelos/canal/email' element={<CreateEmailTemplate />} />
              <Route path='/modelos/canal/sms' element={< TemplateAdd typeChannel='SMS' />} />
              <Route path='/modelos/editar' element={<EditTemplate />} />
              <Route path='/modelos/serviços/editar' element={<EditTemplateHTML />} />
              <Route path="/listagens" element={<Sheets />} />
              <Route path="/listagens/adicionar" element={<PageAddSheets />} />
              <Route path="/campanhas" element={<CampaignPage />} />
              <Route path="/campanhas/adicionar" element={<PageOptionsCampaign />} />
              <Route path="/campanhas/adicionar/modo-personalizado" element={<PageAddPersonalized />} />

              <Route path="/template/view" element={<TemplateView />} />
              <Route path="/suporte" element={<Support />} />
              <Route path="/suporte/adicionar" element={<PageAddRequest />} />
              <Route path="*" element={<Erro />} />
            </>
          )}

          {userAuthenticated && userTypeCookie === 'Atendente' && (
            <>
              <Route path="/painel" element={<DashboardPage />} />
              <Route path="/channel" element={<ChannelPage />} />
              <Route path="*" element={<Erro />} />
            </>
          )}
        </>
      )}
    </Routes>
  );
}

export default App;
