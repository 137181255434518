// Description: Render the notification data
// Updated: 05/12/2024
// Author: Simon Franklin

// React
import React, { useState } from 'react'

// Material UI Components
import { Box, Typography } from '@mui/material'

// Components and Functions for the project
import Alert from '../../assets/Icons/components/Alert'
import StatusIcon from '../StatusLabel/StatusIcon'
import { formatNotifyDate, truncateText } from '../../utils/utils'

// Render the Component
const NotifyComponent = ({ notification, updateNotificationStatus, setExpandedNotification }) => {
    const [expanded, setExpanded] = useState(false);

    return (
        <Box onClick={() => !expanded && (setExpanded(true), setExpandedNotification(true))} sx={{
            display: 'flex',
            flexDirection: expanded ? 'column' : 'row',
            padding: expanded ? '20px 25px' : '0 18px',
            gap: '21px',
            height: expanded ? '100%' : '71px',
            alignItems: expanded ? 'flex-start' : 'center',
            background: '#FFF',
            minWidth: '100%',
            position: expanded ? 'absolute' : 'relative',
            transition: 'all 0.5s ease-in-out',
            boxShadow: expanded ? '0px 10px 20px rgba(0, 0, 0, 0.3)' : '0px',
            zIndex: expanded ? 40 : 'inherit',
            top: expanded ? 0 : 'auto',
            left: expanded ? 0 : 'auto',
        }}>
            {
                !expanded ? (
                    <>
                        <Alert color={notification.iconColor} />
                        <Box sx={{ display: 'flex', flexDirection: 'column', height: '42px', justifyContent: 'space-between', maxWidth: '76%' }}>
                            <div style={{ display: 'flex', gap: '.3rem' }}>
                                <Typography children='Equipe 123zap' sx={{ fontSize: '14px', fontWeight: '600' }} />
                                <Typography children={`- ${formatNotifyDate(notification.date)}`} sx={{ fontSize: '14px', fontWeight: '500', color: '#848484' }} />
                            </div>
                            <Typography children={truncateText(notification.text, 40)} sx={{ fontSize: '12px', fontWeight: '500', color: '#848484' }} />
                        </Box>
                        <Box sx={{ position: 'absolute', height: '71px', display: 'flex', alignItems: 'center', top: '0', right: '18px' }}>
                            <Box onClick={notification.read ? null : () => updateNotificationStatus(notification.id)} sx={{ cursor: 'pointer', transition: '.3s ease-in-out', '&:hover': { transform: 'scale(1.1)' } }}>
                                <StatusIcon status="Check" color={!notification.read ? '#000' : '#A986FF'} width="19.2px" height="19.2px" />
                            </Box>
                        </Box>
                    </>
                ) : (
                    <Box sx={{ display: 'flex', flexDirection: 'column', minWidth: '100%', gap: '25px' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Box onClick={() => (setExpanded(false), setExpandedNotification(false))} sx={{ fontSize: '16px', fontWeight: '600', color: '#848484' }}>
                                <StatusIcon status="Close" color={"#848484"} width="25px" height="25px" />
                            </Box>
                        </Box>

                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>

                            <div style={{ display: 'flex', gap: '.3rem', alignItems: 'center' }}>
                                <Alert color={notification.iconColor} />
                                <Typography children='Equipe 123zap' sx={{ marginLeft: '20px', fontSize: '20px', fontWeight: '600' }} />
                                <Typography children={`- ${formatNotifyDate(notification.date)}`} sx={{ fontSize: '20px', fontWeight: '500', color: '#848484', textAlign: 'justify' }} />
                            </div>
                            <Typography children={notification.text} sx={{ fontSize: '16px', fontWeight: '500', color: '#848484' }} />
                        </Box>



                        <Box onClick={notification.read ? null : () => updateNotificationStatus(notification.id)} sx={{ cursor: 'pointer', transition: '.3s ease-in-out', background: '#A986FF', padding: '5px 15px', borderRadius: '8px', display: 'flex', justifyContent: 'center', alignSelf: 'flex' }}>
                            <StatusIcon status="Check" color={!notification.read ? '#000' : '#FFF'} width="25px" height="25px" />
                        </Box>
                    </Box>
                )
            }
        </Box>
    )
}

export default NotifyComponent