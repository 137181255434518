// This endpoint is used to add a new SMS channel to the user.
// Author: Vitor de Jesus

// Documentation: 
import axios from 'axios';
// Função que chama a API para adicionar o canal SMS
export async function ChannelAddSMS(data) {
    try {
        const token = localStorage.getItem('jwt_token');
        const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/channel/sms/add`, 
            data,
            { 
                headers: { 
                    Authorization: `Bearer ${token}` 
                },
            }
        );

        // Se não houver erro, retorna a resposta com dados
        return response.data;

    } catch (error) {
        console.log('Erro:', error);  // Log do erro completo
        if (error.response) {
            console.log('Resposta do servidor:', error.response); // Verifique a resposta do servidor
            
            const errorMessage = error.response.data.error;  // Acessando 'data.error' diretamente
    
            // Verifica se a mensagem de erro do servidor é a esperada
            if (errorMessage === 'Já existe um canal SMS para este cliente') {
                throw new Error('Já existe um canal SMS para este cliente');
            }
    
            // Caso contrário, lança um erro genérico
            throw new Error('Erro ao processar a solicitação');
        } else if (error.request) {
            // Se o erro for no request (sem resposta do servidor)
            throw new Error('Erro ao conectar com o servidor');
        } else {
            // Qualquer outro erro relacionado à configuração da requisição
            throw new Error('Erro na requisição');
        }
    }    
}
