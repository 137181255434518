import axios from 'axios';

export async function Register(data) {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/auth/register`, 
            data,
            { headers: { 'Content-Type': 'application/json' } }
        );
        return response.data;
    } catch (error) {
        if (error.response) {
            const status = error.response.status;
            if (status === 429) {
                return {
                    status: 429,
                    message: 'Muitas solicitações. Tente novamente mais tarde.'
                };
            }

            // Verificar se o erro é de conflito (duplicação de username ou email)
            if (status === 409) {
                return {
                    status: 409,
                    message: error.response.data.message || 'Ocorreu um erro ao tentar registrar. Verifique os dados informados.'
                };
            }

            // Outros erros do servidor
            return {
                status: status,
                message: error.response.data.message || 'Erro inesperado. Tente novamente mais tarde.'
            };
        } else {
            return { 
                status: 500,
                message: 'Erro inesperado. Tente novamente mais tarde.' 
            };
        }
    }
}
