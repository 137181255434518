import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Sidebar, { sidebarDatabase } from './Sidebar'
import ConfigurationMenu from './Menu';


const ConfigurationPage = () => {
  const [selectedOption, setSelectedOption] = useState(sidebarDatabase[0]);
  const [search, setSearch] = useState(null);
  const fields = sidebarDatabase.map((item) => item.fields).flat();
  const [searchFields, setSearchFields] = useState([]);

  useEffect(() => {
    if (search) {
      setSearchFields(fields.filter((item) => item.includes(search)));
    }
  }, [search]);

  return (
    <Box sx={{
      backgroundColor: '#F9F9F9',
      minHeight: '100vh',
      display: 'flex'
    }}>
      <Sidebar selected={selectedOption} setSelected={setSelectedOption} />
      <ConfigurationMenu search={search} setSearch={setSearch} selected={selectedOption} searchFields={searchFields} />
    </Box>
  )
}

export default ConfigurationPage