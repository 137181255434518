// Description: Component to welcome the user with 
// Updated: 03/12/2024
// Author: Simon Franklin

// React
import React from 'react'

// Material UI Components 
import { Box, Button } from '@mui/material'

// Components and functions for the project
import Titles from '../../components/Titles/Titles'
import Badge from '../../assets/badges/image.png';
import ConfigIcon from '../../assets/images/config.png';
import { ReactComponent as TagIcon } from '../../assets/Icons/svg/TagIcon.svg';
import { ReactComponent as CampaignIcon } from '../../assets/Icons/svg/CampaignIcon.svg';

const Welcome = ({ userInfo, dataFromAPI, userAvatar }) => {
    return (
        <Box flex={1} display={'flex'} flexDirection={'column'} borderRadius={'12px'} overflow={'hidden'} sx={{ minHeight: { xs: '220px', xl: '312px', lg: '312px', sm: '290px' } }}>
            <Box
                sx={{
                    background: '#8142FC',
                    flex: 1,
                    display: 'flex',
                    padding: { xs: '18px', sm: '30px' },
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                }}
            >
                <Box display={'flex'} justifyContent={'space-between'} minWidth={'100%'}>
                    <Box display={'flex'} gap={'15px'}>
                        <Box component='img' src={userAvatar} sx={{ borderRadius: '12px', width: { xs: '46px', sm: '71px' }, height: { xs: '46px', sm: '71px' } }} alt='Logo' />
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', maxHeight: { xs: '46px', sm: '71px' } }}>
                            <Titles children={`Olá, ${userInfo['NAME']}`} styles={{ fontSize: { sm: '30px', xs: '20px' } }} color={'#F5F3FF'} />
                            <Titles children={'O que iremos fazer hoje?'} styles={{ fontSize: { sm: '16px', xs: '11px' } }} color={'#F5F3FF'} />
                        </Box>
                    </Box>
                    <Box
                        component={'img'}
                        sx={{
                            cursor: 'pointer',
                            transition: '.3s',
                            width: '19.17px',
                            height: '18.84px',
                            '&:hover': {
                                scale: '1.1'
                            }
                        }}
                        src={ConfigIcon}
                        alt='Configuration'
                    />
                </Box>
                <Box display={'flex'} justifyContent={'space-between'} gap={'15px'}>
                    <Box
                        sx={{
                            background: '#8033F4',
                            color: '#EDE8FF',
                            borderRadius: '6px',
                            height: '33px',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '7px',
                            flex: '1',
                            padding: '0 0 0 10px',
                        }}
                    >
                        <TagIcon width={'18px'} height={'18px'} fill={'#EDE8FF'} />
                        <Titles color={'#EDE8FF'} children={`Canais abertos`} styles={{ fontSize: { xl: '16px', lg: '12px', md: '16px', sm: '16px', xs: '11px' } }} />
                        <Titles color={'#EDE8FF'} children={`${dataFromAPI && dataFromAPI['total_channels'] ? dataFromAPI['total_channels'].toString().padStart(2, '0') : '0'}`} styles={{ fontSize: { xl: '16px', lg: '12px', md: '16px', sm: '16px', xs: '11px' } }} />
                    </Box>
                    <Box
                        sx={{
                            background: '#8033F4',
                            color: '#EDE8FF',
                            borderRadius: '6px',
                            height: '33px',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '7px',
                            flex: '1',
                            padding: '0 0 0 10px'
                        }}
                    >
                        <CampaignIcon width={'18px'} height={'18px'} fill={'#EDE8FF'} />
                        <Titles color={'#EDE8FF'} children={`Campanhas ativas`} styles={{ fontSize: { xl: '16px', lg: '12px', md: '16px', sm: '16px', xs: '11px' } }} />
                        <Titles color={'#EDE8FF'} children={`${dataFromAPI && dataFromAPI['total_campaigns'] ? dataFromAPI['total_campaigns'].toString().padStart(2, '0') : '0'}`} styles={{ fontSize: { xl: '16px', lg: '12px', md: '16px', sm: '16px', xs: '11px' } }} />

                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    background: '#8033F4',
                    height: { xs: '70px', sm: '100px' },
                    padding: { xs: '0 18px', sm: '0 30px' },
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
            >
                <Box display={'flex'} gap={'10px'}>
                    <Box
                        component={'img'}
                        sx={{
                            width: { xs: '40px', sm: '56px' },
                            height: { xs: '40px', sm: '56px' },
                        }}
                        alt={'Badge'}
                        src={Badge}
                    />
                    <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} >
                        <Titles color={'#F5F3FF'} children={'Plano atual'} styles={{ fontSize: { sm: '16px', xs: '13px' } }} />
                        <Titles
                            color={'#F5F3FF'}
                            children={
                                dataFromAPI &&
                                    dataFromAPI['plan'] &&
                                    dataFromAPI['plan']['plan_name']
                                    ? dataFromAPI['plan']['plan_name']
                                    : 'Plano não encontrado'
                            }
                            styles={{ fontSize: { xl: '24px', lg: '16px', sm: '20px', xs: '16px' } }}
                        />
                    </Box>
                </Box>
                <Button
                    sx={{
                        background: '#5F1BBC',
                        borderRadius: '6px',
                        padding: { xs: '6px 12px', sm: '12px 24px' },
                        color: '#DDD4FF',
                        fontSize: { xs: '12px', sm: '16px' },
                        transition: '.3s',
                        '&:hover': {
                            scale: '1.05',
                            background: '#5F1BBC',
                        }
                    }}
                >
                    Atualizar Plano
                </Button>
            </Box>
        </Box>
    )
}

export default Welcome