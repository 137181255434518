import { Box, Typography } from '@mui/material'
import React, { useState } from 'react'

import Search from '../../assets/Icons/components/Search';

const SearchBar = ({ setSearch, search }) => {
  return (
    <div style={{
      display: 'flex',
      width: '605px',
      height: '41px',
      borderRadius: '20px',
      border: '1px solid #4F189A',
      background: '#F5F3FF',
      alignItems: 'center'
    }}>
      <div style={{ width: '48px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Search {...{ color: '#000', height: '24px', width: '24px' }} />
      </div>
      <input placeholder='Pesquisar nas configurações' style={{ outline: 'none', border: 'none', background: 'none' }} type='text' value={search} onChange={(e) => setSearch(e.target.value)} />
    </div>
  )
}

const ConfigurationMenu = ({ search, setSearch, selected, searchFields }) => {

  return (
    <Box
      sx={{
        padding: '49px 94px',
        display: 'flex',
        flexDirection: 'column',
        gap: '45px',
        width: '100%',
        flex: '1'
      }}
    >
      <SearchBar search={search} setSearch={setSearch} />
      {search ? (
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '7px',
          overflow: 'hidden',
          boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.25)'
        }}>
          {searchFields.map((item, index) => (
            <Box key={index} sx={{
              borderBottom: index === searchFields.length - 1 ? 'none' : '1px solid #848484',
              width: '100%',
              padding: '13px 23px',
              background: '#FFF',
              cursor: 'pointer',
              transition: '.3s ease-in-out',
              '&:hover': {
                background: 'lightgray'
              }
            }}>
              <Typography children={item} fontSize={'14px'} color={'#303035'} />
            </Box>
          ))}
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '15px'
          }}
        >
          <Typography sx={{ fontWeight: '500', fontSize: '20px', color: '#303035' }} children={selected.name} />

          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '7px',
            overflow: 'hidden',
            boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.25)'
          }}>
            {selected.fields.map((item, index) => (
              <Box key={index} sx={{
                borderBottom: index === selected.fields.length - 1 ? 'none' : '1px solid #848484',
                width: '100%',
                padding: '13px 23px',
                background: '#FFF',
                cursor: 'pointer',
                transition: '.3s ease-in-out',
                '&:hover': {
                  background: 'lightgray'
                }
              }}>
                <Typography children={item} fontSize={'14px'} color={'#303035'} />
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default ConfigurationMenu
