import React from 'react'

const Alert = ({ color }) => {
    return (
        <svg
            width="42"
            height="43"
            viewBox="0 0 42 43"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                cx="21"
                cy="21.5"
                r="20"
                stroke={`${color}`}
                strokeWidth="1"
            />
            <path
                d="M23.0777 27.7181L23.0774 27.7178C22.5171 27.1504 21.8141 26.8668 21.0026 26.8668C20.1913 26.8668 19.4881 27.1504 18.9263 27.7172L18.9262 27.7173C18.3657 28.2832 18.0853 28.9872 18.0853 29.797C18.0853 30.6062 18.3643 31.3082 18.9247 31.869L18.9248 31.8691C19.4856 32.4299 20.1879 32.7092 20.9974 32.7092C21.8067 32.7092 22.5092 32.4299 23.0715 31.8698C23.6342 31.3091 23.9147 30.6069 23.9147 29.797C23.9147 28.9879 23.6359 28.2841 23.0777 27.7181Z"
                stroke={`${color}`}
                strokeWidth="1"
            />
            <path
                d="M18.1588 23.5829V24.0829H18.6588H23.3412H23.8412V23.5829V10.9509V10.4509H23.3412H18.6588H18.1588V10.9509V23.5829Z"
                stroke={`${color}`}
                strokeWidth="1"
            />
        </svg>

    )
}

export default Alert