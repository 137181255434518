// Description: Render The Login Form in mobile version
// Updated: 27/11/2024
// Author: Simon Franklin

// React
import React, { useState } from 'react';

// Material UI Components
import { Box, Button, Checkbox, FormControlLabel, Grid, TextField, Typography } from '@mui/material';

// Components and functions for the project
import { useNavigate } from 'react-router-dom';
import Logo from '../../assets/ilustrations/Logo';
import themeColors from '../../components/ThemeColors/ThemeColors';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

// Render The Page
const MobileLoginPage = ({ username, setUsername, password, setPassword, message, loading, checked, handleChange, handleSubmit }) => {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);

    return (
        <Box sx={{
            minHeight: '100vh',
            position: 'relative',
            width: '100%',
            backgroundColor: '#FFF'
        }}>
            <div style={{
                position: 'absolute',
                top: '24px',
                left: '24px',
            }}>
                <Logo />
            </div>

            <form onSubmit={handleSubmit} style={{
                padding: '194px 40px 0 40px',
                display: 'flex',
                flexDirection: 'column'
            }}>
                <Typography children='Faça seu login' color={themeColors.purple500} fontWeight={'600'} fontSize={'30px'} marginBottom={'35px'} />

                <TextField
                    variant="outlined"
                    placeholder='Usuário ou Email'
                    fullWidth
                    margin="normal"
                    name="username"
                    value={username}
                    autoFocus={true}
                    size="small"
                    onChange={(e) => setUsername(e.target.value)}
                    sx={{ background: message === 'Esse email ou usuário é inválido.' || (message === 'Por favor, preencha todos os campos.' && username === '') || message === 'Usuário ou senha inválidos.' ? '#F8D6D3' : '#F9F9F9', marginBottom: '16px', borderRadius: '6px', 'MuiInputBase-input': { height: '48px' } }}
                    InputProps={{
                        endAdornment: (
                            message === 'Esse email ou usuário é inválido.' || (message === 'Por favor, preencha todos os campos.' && username === '') || message === 'Usuário ou senha inválidos.' ?
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <mask id="mask0_34_1183" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
                                        <rect width="20" height="20" fill="#D9D9D9" />
                                    </mask>
                                    <g mask="url(#mask0_34_1183)">
                                        <path d="M8 10.9743C8.11622 10.9743 8.21367 10.935 8.29233 10.8563C8.371 10.7778 8.41033 10.6804 8.41033 10.5642C8.41033 10.4478 8.371 10.3504 8.29233 10.2718C8.21367 10.1932 8.11622 10.1538 8 10.1538C7.88378 10.1538 7.78633 10.1932 7.70767 10.2718C7.629 10.3504 7.58967 10.4478 7.58967 10.5642C7.58967 10.6804 7.629 10.7778 7.70767 10.8563C7.78633 10.935 7.88378 10.9743 8 10.9743ZM7.66667 8.76917H8.33333V4.76917H7.66667V8.76917ZM8.00217 14C7.1725 14 6.39244 13.8426 5.662 13.5277C4.93167 13.2128 4.29633 12.7854 3.756 12.2457C3.21567 11.7059 2.78794 11.0711 2.47283 10.3413C2.15761 9.61167 2 8.83194 2 8.00217C2 7.1725 2.15744 6.39244 2.47233 5.662C2.78722 4.93167 3.21456 4.29633 3.75433 3.756C4.29411 3.21567 4.92889 2.78794 5.65867 2.47283C6.38833 2.15761 7.16806 2 7.99783 2C8.8275 2 9.60756 2.15744 10.338 2.47233C11.0683 2.78722 11.7037 3.21456 12.244 3.75433C12.7843 4.29411 13.2121 4.92889 13.5272 5.65867C13.8424 6.38833 14 7.16806 14 7.99783C14 8.8275 13.8426 9.60756 13.5277 10.338C13.2128 11.0683 12.7854 11.7037 12.2457 12.244C11.7059 12.7843 11.0711 13.2121 10.3413 13.5272C9.61167 13.8424 8.83194 14 8.00217 14Z" fill="#CD4747" />
                                    </g>
                                </svg>
                                : null
                        )
                    }}
                />

                <Box position='relative'>
                    <Box
                        sx={{
                            cursor: 'pointer',
                            position: 'absolute',
                            zIndex: '10',
                            top: '24px',
                            right: '5px'
                        }}
                        onClick={() => setShowPassword(prev => !prev)}
                    >
                        {showPassword ? <VisibilityOffIcon sx={{ fill: '#000' }} /> : <VisibilityIcon sx={{ fill: '#000' }} />}
                    </Box>
                    <TextField
                        placeholder='Senha'
                        type={showPassword ? "text" : "password"}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        sx={{ background: message === 'Usuário ou senha inválidos.' || (message === 'Por favor, preencha todos os campos.' && password === '') ? '#F8D6D3' : '#F9F9F9', borderRadius: '6px' }}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        size="small"
                        InputProps={{
                            endAdornment: (
                                message === 'Usuário ou senha inválidos.' || (message === 'Por favor, preencha todos os campos.' && password === '') ?
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <mask id="mask0_34_1183" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
                                            <rect width="20" height="20" fill="#D9D9D9" />
                                        </mask>
                                        <g mask="url(#mask0_34_1183)">
                                            <path d="M8 10.9743C8.11622 10.9743 8.21367 10.935 8.29233 10.8563C8.371 10.7778 8.41033 10.6804 8.41033 10.5642C8.41033 10.4478 8.371 10.3504 8.29233 10.2718C8.21367 10.1932 8.11622 10.1538 8 10.1538C7.88378 10.1538 7.78633 10.1932 7.70767 10.2718C7.629 10.3504 7.58967 10.4478 7.58967 10.5642C7.58967 10.6804 7.629 10.7778 7.70767 10.8563C7.78633 10.935 7.88378 10.9743 8 10.9743ZM7.66667 8.76917H8.33333V4.76917H7.66667V8.76917ZM8.00217 14C7.1725 14 6.39244 13.8426 5.662 13.5277C4.93167 13.2128 4.29633 12.7854 3.756 12.2457C3.21567 11.7059 2.78794 11.0711 2.47283 10.3413C2.15761 9.61167 2 8.83194 2 8.00217C2 7.1725 2.15744 6.39244 2.47233 5.662C2.78722 4.93167 3.21456 4.29633 3.75433 3.756C4.29411 3.21567 4.92889 2.78794 5.65867 2.47283C6.38833 2.15761 7.16806 2 7.99783 2C8.8275 2 9.60756 2.15744 10.338 2.47233C11.0683 2.78722 11.7037 3.21456 12.244 3.75433C12.7843 4.29411 13.2121 4.92889 13.5272 5.65867C13.8424 6.38833 14 7.16806 14 7.99783C14 8.8275 13.8426 9.60756 13.5277 10.338C13.2128 11.0683 12.7854 11.7037 12.2457 12.244C11.7059 12.7843 11.0711 13.2121 10.3413 13.5272C9.61167 13.8424 8.83194 14 8.00217 14Z" fill="#CD4747" />
                                        </g>
                                    </svg>
                                    : null
                            )
                        }}
                        autoComplete='new-password'
                    />
                </Box>
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checked}
                                    onChange={handleChange}
                                    name="checked"
                                    color="primary"
                                    size="small"
                                    style={{ color: checked ? themeColors.purple500 : "lightgray" }}
                                />
                            }
                            label={
                                <p style={{ color: "#52525B", fontSize: '12px', fontFamily: 'Satoshi', border: 'none', padding: '2px 0 0' }}>
                                    Mantenha-me conectado
                                </p>
                            }
                        />
                    </Grid>
                    <Grid container spacing={1} style={{ marginTop: '17px', gap: '8px' }}>
                        <Grid item xs={12}>
                            <Button type="submit" variant="contained"
                                className={loading ? "button-disabled" : "button-default"}
                                sx={{ height: '48px' }}
                                fullWidth disabled={loading}>
                                Entrar no sistema
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="outlined" sx={{ height: '48px' }} fullWidth onClick={() => navigate("/cadastro")}>
                                Ainda não tenho uma conta
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginTop: '19px',
                    marginBottom: '22px'
                }}>
                    <Box sx={{
                        border: '1px solid #848484',
                        width: '118.47px'
                    }} />
                    <Typography children='Ou' color={'#848484'} />
                    <Box sx={{
                        border: '1px solid #848484',
                        width: '118.47px'
                    }} />
                </Box>

                <Box style={{ margin: '0 auto' }}>
                    <a href="/login/recovery" style={{ color: themeColors.purple400, textDecoration: 'none', fontSize: '12px', fontFamily: 'Satoshi' }}>Esqueci minha senha</a>
                </Box>
            </form>

            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                padding: '10px',
                background: message === 'Senha alterada com sucesso.' ? '#D3F8D3' : message ? '#F8D6D3' : 'transparent',
                position: 'absolute',
                bottom: '150px',
                borderRadius: '10px',
                left: '50%',
                transform: 'translateX(-50%)'
            }}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <mask id="mask0_34_1183" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
                        <rect width="20" height="20" fill="#D9D9D9" />
                    </mask>
                    <g mask="url(#mask0_34_1183)">
                        <path fill={message === 'Senha alterada com sucesso.' ? 'rgb(47 120 60)' : message ? '#CD4747' : 'transparent'} d="M8 10.9743C8.11622 10.9743 8.21367 10.935 8.29233 10.8563C8.371 10.7778 8.41033 10.6804 8.41033 10.5642C8.41033 10.4478 8.371 10.3504 8.29233 10.2718C8.21367 10.1932 8.11622 10.1538 8 10.1538C7.88378 10.1538 7.78633 10.1932 7.70767 10.2718C7.629 10.3504 7.58967 10.4478 7.58967 10.5642C7.58967 10.6804 7.629 10.7778 7.70767 10.8563C7.78633 10.935 7.88378 10.9743 8 10.9743ZM7.66667 8.76917H8.33333V4.76917H7.66667V8.76917ZM8.00217 14C7.1725 14 6.39244 13.8426 5.662 13.5277C4.93167 13.2128 4.29633 12.7854 3.756 12.2457C3.21567 11.7059 2.78794 11.0711 2.47283 10.3413C2.15761 9.61167 2 8.83194 2 8.00217C2 7.1725 2.15744 6.39244 2.47233 5.662C2.78722 4.93167 3.21456 4.29633 3.75433 3.756C4.29411 3.21567 4.92889 2.78794 5.65867 2.47283C6.38833 2.15761 7.16806 2 7.99783 2C8.8275 2 9.60756 2.15744 10.338 2.47233C11.0683 2.78722 11.7037 3.21456 12.244 3.75433C12.7843 4.29411 13.2121 4.92889 13.5272 5.65867C13.8424 6.38833 14 7.16806 14 7.99783C14 8.8275 13.8426 9.60756 13.5277 10.338C13.2128 11.0683 12.7854 11.7037 12.2457 12.244C11.7059 12.7843 11.0711 13.2121 10.3413 13.5272C9.61167 13.8424 8.83194 14 8.00217 14Z" />
                    </g>
                </svg>
                <Typography style={{ color: message === 'Senha alterada com sucesso.' ? 'rgb(47 120 60)' : message ? '#CD4747' : 'transparent', fontSize: '12px', fontFamily: 'Satoshi', marginLeft: '10px' }}>
                    {message}
                </Typography>
            </div>
        </Box>
    )
}

export default MobileLoginPage