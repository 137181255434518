// This endpoint is used to get all requests made by the user. It is used in the MyRequests page.
// This endpoint is used in the MyRequests page.
// Author: Vitor de Jesus

// Documentation: https://briotecnologia.atlassian.net/wiki/spaces/DI1/pages/136773687/RequestRoutes

import axios from 'axios';

export async function RequestByID() {
    const token = localStorage.getItem('jwt_token');
    
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/requests`, 
            { 
                headers: { 
                    Authorization: `Bearer ${token}` 
                },
            }

        );
        
        const responseData = response.data;

        return responseData;
    } catch (error) {
        console.error('Erro ao buscar dados da API:', error);
    }
}