// Desc: Page to add a new WhatsApp instance
// Update: 22/10/2024

// React Components and DOM Elements
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Material UI Components
import Box from '@mui/material/Box';

// Components and functions from the project
import { isAuthenticated } from '../../authMiddleware';
import { RequestCreate } from '../../api/backend/Request/RequestCreate';

import CardList from '../../components/CardList/CardList';
import CardModal from '../../components/CardList/CardModal';
import HeaderPage from '../../components/HeaderPage/HeaderPage';
import Loading from '../../components/Loading/Loading';
import Titles from '../../components/Titles/Titles';
import { ChannelAddEmail } from '../../api/backend/Channels/ChannelAddEmail';
import { ChannelAddSMS } from '../../api/backend/Channels/ChannelAddSMS';
import { ChannelAddWppAPI } from '../../api/backend/Channels/ChannelAddWppAPI';

import ThemeColors from '../../components/ThemeColors/ThemeColors';
import ModalR from '../../components/Modals/ModalR';

function ChannelPageAdd() {

  const { authenticated } = isAuthenticated();
  const [userInfo, setUserInfo] = useState({});
  let Navigate = useNavigate();

  const [showLoading, setShowLoading] = useState(true);
  const [input, setInput] = useState("");
  const [input2, setInput2] = useState("");
  const [input3, setInput3] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [loading, setLoading] = useState(false);

  const [openModalConfirmOK, setOpenModalConfirmOK] = useState(false);
  const [openModalConfirmError, setOpenModalConfirmError] = useState(false);

  const [messageError, setMessageError] = useState("");
  const [mensagem, setMensagem] = useState("");

  const openModal = (type) => {
    setModalType(type);
    setShowModal(true);
  }

  useEffect(() => {
    if (authenticated) {
      const { decryptedData } = isAuthenticated();
      setUserInfo(decryptedData);
      console.log(decryptedData);
    }
  }, []);

  const HandleDocs = () => {
    setOpenModalConfirmOK(false);
    Navigate('/canais');
  }

  const addRequest = async () => {

    let info = {

      title: 'Solicitação de Adição de Canal ' + modalType,
      description: 'Solicitação de adição de canal ' + modalType + ' para envio de mensagens personalizadas para os clientes.',
      status: 'Pendente',
      creationdate: new Date().toISOString(),
      updatedate: new Date().toISOString(),
      requesterid: userInfo['EMAIL'],
      user_client: userInfo['UUID'],
      body: {
        type: modalType,
        data: { input, input2, input3 }
      },
      priority: 'A Definir'

    }

    const addRequestUser = await RequestCreate(info);

  }

  const HandleAddChannel = async () => {
    setLoading(true);

    if (modalType === 'Email') {
      try {
        const addEmail = await ChannelAddEmail({ email: input , name: input2 });
        if (addEmail.message === 'Email identity added successfully') {

          let mensagem = (
            <span>
              Seu email foi <b style={{ color: '#8033F4' }}>adicionado</b> com sucesso!<br/> O email é utilizado para envio de mensagens personalizadas para os seus clientes.
            </span>
          );

          setMensagem(mensagem);
          setShowModal(false);
          setOpenModalConfirmOK(true);
          addRequest();
          setInput("");
          setInput2("");

        }
      } catch (error) {

        let mensagem = (
          <span>
            Ocorreu um erro ao adicionar o email.<br/> Consulte nossa <b style={{ color: '#8033F4' }}>documentação</b> para ficar por dentro de todos os detalhes e orientações.
          </span>
        );

        setMessageError(mensagem);
        setShowModal(false);
        setOpenModalConfirmError(true);
        setInput("");
        setInput2("");

      } finally {
        setLoading(false);
      }
    } else if (modalType === 'SMS') {
      try {
        const addSMS = await ChannelAddSMS({ 'confirm': input });

        if (addSMS.message === 'SMS channel added successfully') {

          let mensagem = (
            <span>
              Seu canal de SMS foi <b style={{ color: '#8033F4' }}>adicionado</b> com sucesso!<br/> No Brasil, o envio de SMS é feito através de um número curto.
            </span>
          );
          setMensagem(mensagem);
          setShowModal(false);
          setOpenModalConfirmOK(true);
          addRequest();
          setInput("");
          setInput2("");
        }

      } catch (error) {

        let mensagem = null

        if (error && error.message === 'Já existe um canal SMS para este cliente') {
          mensagem = (
            <span>
              Este cliente já possui um canal SMS ativo.<br/> Consulte nossa <b style={{ color: '#8033F4' }}>documentação</b> para ficar por dentro de todos os detalhes e orientações.
            </span>
          );
        } else {
          mensagem = (
            <span>
              Ocorreu um erro ao adicionar o canal SMS.<br/> Consulte nossa <b style={{ color: '#8033F4' }}>documentação</b> para ficar por dentro de todos os detalhes e orientações.
            </span>
          );
        }

        setMessageError(mensagem);
        setShowModal(false);
        setOpenModalConfirmError(true);
        setInput("");
        setInput2("");

      } finally {
        setLoading(false);
      }
    }
    else if (modalType === 'WhatsApp Business') {

      try {
        const addWppAPI = await ChannelAddWppAPI({ 'url': input, 'token': input2 , 'name': input3 });

        if (addWppAPI.message === 'WhatsApp API channel added successfully') {

          let mensagem = (
            <span>
              Seu canal de WhatsApp API foi <b style={{ color: '#8033F4' }}>adicionado</b> com sucesso!<br/>
            </span>
          );

          setMensagem(mensagem);
          setShowModal(false);
          setOpenModalConfirmOK(true);
          addRequest();
          setInput("");
          setInput2("");
          setInput3("");

        }

      } catch (error) {

        let mensagem = null

        if (error && error.message === 'Já existe um canal WhatsApp API para este cliente') {
          mensagem = (
            <span>
              Este cliente já possui um canal WhatsApp API ativo.<br/> Consulte nossa <b style={{ color: '#8033F4' }}>documentação</b> para ficar por dentro de todos os detalhes e orientações.
            </span>
          );
        } else {
          mensagem = (
            <span>
              Ocorreu um erro ao adicionar o canal WhatsApp API.<br/> Consulte nossa <b style={{ color: '#8033F4' }}>documentação</b> para ficar por dentro de todos os detalhes e orientações.
            </span>
          );
        }

        setMessageError(mensagem);
        setShowModal(false);
        setOpenModalConfirmError(true);
        setInput("");
        setInput2("");
        setInput3("");

      } finally {
        setLoading(false);
      }
    }
  }
  
  const HandleRedirectAdd = () => {
    Navigate('/canais/adicionar/whatsapp');
  }

  const HandleChannels = () => {
    setOpenModalConfirmOK(false);
  }

  const channelData = [
    { title: "WhatsApp Web", text: "Adicione um número de Whatsapp para enviar mensagens personalizadas para seus clientes.", icon: "WhatsApp", corFundo: "#DDD4FF", titleColor: "#8033F4", onClick: HandleRedirectAdd, pago: false },
    { title: "WhatsApp API", text: "Avisos de cobrança, faturas e lembretes de pagamento. personalize mensagens para manter seus clientes informados", icon: "WhatsApp Business", corFundo: "#DDD4FF", titleColor: "#8033F4", onClick: () => openModal("WhatsApp Business"), pago: true },
    { title: "Email", text: "Solicite um endereço de email personalizado para enviar mensagens de email para os seus clientes.", icon: "Email", corFundo: "#DDD4FF", titleColor: "#8033F4", onClick: () => openModal("Email"), pago: true },
    { title: "SMS", text: "Solicite um número de telefone para enviar mensagens de texto para os seus clientes.", icon: "SMS", corFundo: "#DDD4FF", titleColor: "#8033F4", onClick: () => openModal("SMS"), pago: true },
  ];

  useEffect(() => {
    setTimeout(() => setShowLoading(false), 1000);
  }, []);

  if (showLoading) {
    return <Loading open={true} />;
  }

  return (
    <>
      <HeaderPage title="Add WhatsApp Instance" />
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          minHeight: { sm: 'calc(100vh - 100px)' },
          bgcolor: { sm: 'background.paper' },
          paddingBottom: 10,
        }}
      >
        <Box sx={{ padding: { xs: '20px 20px 0 20px', sm: '40px 40px 0 40px' }}}>
          <Titles styles={{ fontSize: { xs: "20px", sm: "24px" }}} children={"Qual Canal deseja adicionar?"} color={"#848484"} />
          <CardList data={channelData} />
        </Box>

        <CardModal isOpen={showModal} onClose={() => setShowModal(false)} type={modalType} input={input} setInput={setInput} onConfirm={HandleAddChannel} input2={input2} setInput2={setInput2} input3={input3} setInput3={setInput3} loading={loading} />
      </Box>
      <ModalR
        cancelText="Verificar meus canais"
        color={ThemeColors.purple500}
        confirmText="Ler documentação"
        subTitle={mensagem}
        isOpen={openModalConfirmOK}
        onClose={HandleDocs}
        onConfirm={HandleChannels}
        title={""}
        emoji="emoji02"
        iconColor={ThemeColors.purple400}
      />

      <ModalR
        cancelText="Fechar janela"
        color={ThemeColors.red500}
        confirmText="Ler documentação"
        subTitle={messageError}
        isOpen={openModalConfirmError}
        onClose={() => setOpenModalConfirmError(false)}
        onConfirm={() => setOpenModalConfirmError(false)}
        title={""}
        emoji="emoji10"
        iconColor={ThemeColors.red400}
      />
    </>
  );
}

export default ChannelPageAdd;