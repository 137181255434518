// Desc: This component render the performance indicator of the system
// Update: 12/08/2024

// React imports
import React, { useEffect, useState, useRef } from 'react';

// Material-UI imports
import Box from '@mui/material/Box';
import Chart from 'chart.js/auto';
import DynamicTable from '../Tables/DynamicTable';
import Fade from '@mui/material/Fade';
import { FormControl, Select, MenuItem } from '@mui/material';
import Typography from '@mui/material/Typography';
import ThemeColors from '../ThemeColors/ThemeColors';
import StatusIcon from '../StatusLabel/StatusIcon';
import StatusLabel from '../StatusLabel/StatusLabel';
import VisibilityIcon from '@mui/icons-material/Visibility';

const customTooltip = (context) => {

    let tooltipEl = document.getElementById('chartjs-tooltip');
    if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.id = 'chartjs-tooltip';
        tooltipEl.innerHTML = '<div></div>';
        document.body.appendChild(tooltipEl);
    }

    const tooltipModel = context.tooltip;
    if (tooltipModel.opacity === 0) {
        tooltipEl.classList.remove('show');
        return;
    }

    tooltipEl.classList.add('show');
    tooltipEl.classList.remove('above', 'below', 'no-transform');
    if (tooltipModel.yAlign) {
        tooltipEl.classList.add(tooltipModel.yAlign);
    } else {
        tooltipEl.classList.add('no-transform');
    }

    if (tooltipModel.body) {
        const value = tooltipModel.body[0].lines[0].split(': ')[1];
        const tooltipContent = tooltipEl.querySelector('div');
        if (tooltipContent) {
            tooltipContent.innerHTML = value;
        }
    }

    const position = context.chart.canvas.getBoundingClientRect();
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
    tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY - tooltipEl.offsetHeight + 'px';
    tooltipEl.style.font = tooltipModel.options.bodyFont.string;
    tooltipEl.style.padding = tooltipModel.options.padding + 'px ' + tooltipModel.options.padding + 'px';
    tooltipEl.style.pointerEvents = 'none';
};

// Component render data from dashboard
const PerformanceIndicator = ({ mockup, dataFromAPI }) => {

    const chartRef = useRef(null);
    const [selectedLabel, setSelectedLabel] = useState('Enviadas');
    const [fadeIn, setFadeIn] = useState(false);
    const [keyForChart, setKeyForChart] = useState(Date.now());

    const [ListagemEnviadas, setListagemEnviadas] = useState([]);
    const [ListagemRecebidas, setListagemRecebidas] = useState([]);
    const [ListagemAguardando, setListagemAguardando] = useState([]);
    const [listagemErro, setListagemErro] = useState([]);
    const [listagemGeral, setListagemGeral] = useState([]);
    const [listagemVisualizadas, setListagemVisualizadas] = useState([]);
    const modeList = ['Modo Gráfico', 'Modo Listagem'];
    const [selectedMode, setSelectedMode] = useState('Modo Gráfico');

    useEffect(() => {
        console.log(dataFromAPI, 'dataFromAPI in PerformanceIndicator');

    }, [dataFromAPI]);

    useEffect(() => {
        if (chartRef && chartRef.current && mockup && mockup.labels && mockup.datasets) {
            const labels = mockup.labels;
            const datasets = mockup.datasets;
            const ctx = chartRef.current.getContext('2d');

            const gradient = ctx.createLinearGradient(20, 20, 20, 400);
            gradient.addColorStop(0, '#EDE8FF');
            gradient.addColorStop(1, 'rgba(242, 240, 255, 0)');

            const chart = new Chart(ctx, {
                type: 'line',
                data: {
                    labels: labels,
                    datasets: Object.keys(datasets).map(label => ({
                        label: label,
                        data: label === selectedLabel ? datasets[label].quantidade : [],
                        backgroundColor: gradient,
                        pointBackgroundColor: ThemeColors.purple500,
                        pointRadius: 6.5,
                        fill: true,
                        tension: 0.4
                    }))
                },
                options: {
                    maintainAspectRatio: false,
                    scales: {
                        x: {
                            stacked: true,
                            grid: {
                                drawOnChartArea: true,
                                borderDash: [5, 5],
                                display: false
                            },
                            ticks: {
                                font: {
                                    family: 'Satoshi',
                                    size: 14
                                },
                            }
                            , display: true,
                            position: 'bottom',

                        },
                        y: {
                            stacked: true,
                            beginAtZero: true,
                            grid: {
                                borderDash: [5, 5],
                                display: true
                            },
                            ticks: {
                                display: true,
                            },
                            display: true,
                            position: 'transparent',

                        }
                    },
                    plugins: {
                        legend: {
                            display: false,
                        },
                        tooltip: {
                            enabled: false,
                            external: customTooltip
                        }
                    },
                    elements: {
                        line: {
                            borderWidth: 1,
                            borderColor: ThemeColors.purple300,
                        },
                        point: {
                            hoverRadius: 8,
                            radius: 8
                        },
                    }
                }
            });

            const canvas = chartRef.current;
            const handleMouseLeave = () => {
                const tooltipEl = document.getElementById('chartjs-tooltip');
                if (tooltipEl) {
                    tooltipEl.style.opacity = 0;
                }
            };

            canvas.addEventListener('mouseleave', handleMouseLeave);

            setTimeout(() => {
                setFadeIn(true);
            }, 100);

            return () => {
                chart.destroy();
                canvas.removeEventListener('mouseleave', handleMouseLeave);
            };
        }
    }, [mockup, selectedLabel, keyForChart]);

    const handleIconClick = (label) => {
        setFadeIn(false)
        setSelectedLabel(prevLabel => (prevLabel === label ? null : label));

        setTimeout(() => {
            setFadeIn(true);
        }, 100);
    };

    const handleLabelChange = (event) => {
        setFadeIn(false)
        setSelectedLabel(prevLabel => (prevLabel === event.target.value ? null : event.target.value));

        setTimeout(() => {
            setFadeIn(true);
        }, 100);
    }

    const formatNumber = (number) => {
        if (number === undefined || number === null) {
            return '-';
        }
        const formattedNumber = String(number).replace(/[^\d.-]/g, '');
        return formattedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    };

    const handleChange = (event) => {
        const mode = event.target.value;

        if (mode === 'Modo Listagem') {
            setSelectedMode(mode);
        } else {
            setSelectedMode(mode);
        }

        // Força a remontagem do componente alterando a key
        setKeyForChart(Date.now());
    };

    useEffect(() => {
        if (dataFromAPI && dataFromAPI['data']) {
            setListagemGeral(dataFromAPI['data']);

            const erros = dataFromAPI['data'].filter((item) => item.final_status === 'Falha');
            setListagemErro(erros);

            const Enviadas = dataFromAPI['data'].filter((item) => item.final_status === 'Lido' || item.final_status === 'Entregue');
            setListagemEnviadas(Enviadas);

            const Visualizadas = dataFromAPI['data'].filter((item) => item.final_status === 'Lido');
            setListagemVisualizadas(Visualizadas);

            const Recebidas = Array.isArray(dataFromAPI['recebidas']) ? dataFromAPI['recebidas'] : [];
            setListagemRecebidas(Recebidas);
        }
    }, [dataFromAPI]);


    const getData = () => {
        if (!dataFromAPI || !dataFromAPI['data']) return [];
        switch (selectedLabel) {
            case 'Enviadas':
                return ListagemEnviadas;
            case 'Visualizadas':
                return listagemVisualizadas;
            case 'Recebidas':
                return ListagemRecebidas;
            case 'Aguardando':
                return ListagemAguardando;
            case 'Erro':
                return listagemErro;
            case 'Geral':
                return listagemGeral;
            default:
                return [];
        }
    };

    const getColumns = () => {
        if (selectedLabel === 'Recebidas') {
            return [
                { header: '', field: 'owner', render: (value) => <StatusIcon status={'WhatsApp'} /> },
                { header: 'Canal de entrada', field: 'owner' },
                { header: 'Enviado por', field: 'pushName' },
                { header: 'Identificador', field: 'remoteJid' },
                { header: 'Mensagem', field: 'conversation', render: (value) => (value && value.length > 20 ? value.substring(0, 20) + '...' : value) },
                { header: 'Data de recebimento', field: 'timestamp' }
            ];
        } else {
            return [
                { header: '', field: 'status_email', render: (value) => <StatusIcon status={value ? 'E-mail' : 'WhatsApp'} /> },
                { header: 'Número', field: 'number' },
                { header: 'Modelo', field: 'template_title' },
                { header: 'Criado em', field: 'timestamp' },
                { header: 'Status', field: 'final_status', render: (value) => <StatusLabel status={value} usingOnTable /> }
            ];
        }
    };


    return (
        <Box style={{ flex: 1, display: 'flex', flexDirection: 'column', height: '100%', backgroundColor: '#fff', borderRadius: '10px', margin: '0px 20px 20px 20px', border: '1px solid #D9D9DC' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: { xs: 'start', sm: 'center' }, flexDirection: { xs: 'column', sm: 'row' } }}>

                <Typography sx={{ color: ThemeColors.purple600, fontSize: { xl: '30px', lg: '30px', xs: '20px' }, fontFamily: 'Satoshi-Medium', padding: '30px 0px 0px 30px', flex: { xs: '1', sm: 'none' } }}>
                    Indicadores
                </Typography>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', minWidth: { xs: '100%', sm: '0'}, paddingLeft: '15px' }}>
                    <FormControl sx={{ padding: { xs: '10px 30px 0px 0px', sm: '30px 30px 0px 0px' } }}>
                        <Select
                            sx={{ width: { xl: '200px', lg: '200px', sm: '100%' } }}
                            value={selectedMode}
                            onChange={handleChange}
                        >
                            {modeList.map((mode, index) => (
                                <MenuItem key={index} value={mode}>{mode}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl sx={{ padding: { xs: '10px 30px 0px 0px', sm: '30px 30px 0px 0px' }, display: { xs: 'block', sm: 'none' } }}>
                        <Select
                            sx={{ width: { xl: '200px', lg: '200px', sm: '100%' } }}
                            value={selectedLabel}
                            onChange={handleLabelChange}
                        >
                            {Object.keys(mockup.datasets).map((label, index) => (
                                <MenuItem key={index} value={label}>{label}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            </Box>

            <Box sx={{ display: { xs: 'none', sm: 'flex' }, justifyContent: 'space-between', marginBottom: '20px', padding: '30px', flexWrap: { xs: 'wrap', sm: 'no-wrap' }, gap: { xs: '15px', sm: '0px' } }}>
                {Object.keys(mockup.datasets).map((label, index) => (
                    <Box key={index} onClick={() => handleIconClick(label)} sx={{ cursor: 'pointer', marginRight: { xs: '0', sm: '20px' }, backgroundColor: selectedLabel === label ? ThemeColors.purple100 : '#fff', padding: '10px', borderRadius: '10px', flex: 1, maxWidth: { xs: '140px', sm: 'calc(33.33% - 20px)' }, minWidth: { xs: '140px', sm: 'none' }, border: selectedLabel === label ? `none` : '1px solid #D9D9DC' }}>
                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                            <Box sx={{
                                width: { xl: '40px', lg: '40px', sm: '30px' },
                                height: { xl: '40px', lg: '40px', sm: '30px' },
                                borderRadius: { xl: '10px', sm: '6px' },
                                background: selectedLabel === label ? ThemeColors.purple200 : ThemeColors.gray20,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                {mockup.datasets[label].icone && React.createElement(mockup.datasets[label].icone, { sx: { width: { xl: '20px', lg: '20px', sm: '15px' }, height: { xl: '20px', lg: '20px', sm: '15px' }, color: selectedLabel === label ? ThemeColors.purple600 : ThemeColors.gray40 } })}
                            </Box>
                            <Box style={{ marginLeft: '10px' }}>
                                <Typography sx={{ fontSize: { xl: '18px', lg: '18px', sm: '12px' }, fontFamily: 'Satoshi-Medium', color: selectedLabel === label ? ThemeColors.purple600 : ThemeColors.gray40 }}>
                                    {Array.isArray(mockup.datasets[label].quantidade) ? formatNumber(mockup.datasets[label].quantidade.reduce((acc, value) => acc + value, 0)) : 'N/A'}
                                </Typography>
                                <Typography sx={{ color: 'rgb(0 0 0 / 50%)', fontSize: { xl: '14px', lg: '14px', sm: '11px' } }}>
                                    {label}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                ))}
            </Box>

            {selectedMode === 'Modo Listagem' ? (
                <Box sx={{ flex: 1, padding: { xs: '0px', sm: '20px 30px 30px 30px'}, backgroundColor: '#f9f9f9' }}>
                    <DynamicTable
                        title="Listagem de Templates"
                        data={getData() || []} // Garante que data não seja undefined
                        columns={getColumns()}
                        actions={[
                            {
                                icon: <VisibilityIcon style={{ color: ThemeColors.gray30, fontSize: '14px' }} />,
                                tooltip: 'Visualizar',
                                action: (item) => console.log(item),
                                isVisible: (item) => true
                            }
                        ]}
                        activeSearch={true}
                        titleSearch={'Pesquisar Processos'}
                        titleLabel={'Modelos por página'}
                    />

                </Box>
            ) : (
                <Fade in={fadeIn} timeout={{ enter: 3000, exit: 3000 }} key={keyForChart}>
                    <Box style={{ flex: 1, minHeight: '300px', maxHeight: '60%', padding: '0px 30px 30px 30px' }}>
                        <canvas ref={chartRef}></canvas>
                    </Box>
                </Fade>
            )}
        </Box>
    );
};

export default PerformanceIndicator;