// Custom functions to use in the project

import toast from "react-hot-toast";
import CustomAlert from "../components/Alerts/CustomAlert";
import { isAuthenticated } from '../authMiddleware';
import axios from "axios";
import themeColors from "../components/ThemeColors/ThemeColors";

export const notify = (type, text) => {
  toast.remove();
  toast.custom(<CustomAlert message={text} type={type} />);
};

export const truncateText = (text, number) => {
  if (text.length < number) {
    return text;
  }

  return text.substring(0, number) + "...";
};

// Function to check if cookies are expired
export const checkAuth = () => {
  const authStatus = isAuthenticated();
  const { authenticated } = authStatus;

  return authenticated
};

// Function to create a object from one array
export function createObjectFromArray(array, initialValue) {
  const result = {};
  array.forEach((str) => {
    result[str] = initialValue; // All properties start with this value
  });
  return result;
}

// Function to apply a mask for cpf or cnpj
export const insertMaskForCpfOrCnpj = (cpfOrCnpj) => {
  cpfOrCnpj = cpfOrCnpj.replace(/\D/g, ""); // Remove all that isn't a number

  if (cpfOrCnpj.length <= 11) {
    // Apply cpf mask
    cpfOrCnpj = cpfOrCnpj.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  } else {
    // Apply cnpj mask
    cpfOrCnpj = cpfOrCnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
  }

  return cpfOrCnpj;
}

// Function to insert mask in a phone Number
export const insertMaskForPhoneNumber = (phone) => {
  const noMask = phone.replace(/\D/g, ''); // Remove all that isn't a number
  const { length } = noMask;

  if (length <= 11) { // Insert mask
    return noMask
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(length === 11 ? /(\d{5})(\d)/ : /(\d{4})(\d)/, '$1-$2');
  }

  return phone;
}

// Function to insert Mask in CEP
export const insertMaskForCep = (text) => {
  text = text.replace(/\D/g, "");
  text = text.replace(/(\d{5})(\d)/, '$1-$2');
  text = text.substring(0, 9);

  return text;
}

// Function to render the input content with the variables inside a span
export const updatedField = (text, variablesOfTemplate) => {
  if (variablesOfTemplate !== null) {
    const variables = Object.values(variablesOfTemplate).flat();
    variables.map((variable) => {
      const regex = new RegExp(`{{\\s*${variable}\\s*}}`, "g");
      text = text.replace(
        regex,
        `<span style="color: #4C8435; font-weight: 600;">{{${variable}}}</span>`
      );
    });
  }
  return text;
};

// Function to logout the user
export const handleLogout = (userInfo) => {
  const FRONTURL_BASED = process.env.REACT_APP_FRONTEND_URL;

  if (userInfo['UUID']) {
      axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/logout`, {
          id_client: userInfo['UUID']
      }).then((response) => {
          console.log('response:', response);
          
          // Remove o token do localStorage
          localStorage.removeItem('jwt_token');
          
          // Redireciona para a URL base
          window.location.href = `${FRONTURL_BASED}`;
      }).catch((error) => {
          console.error('error:', error);
      });
  }
};

// Function to get the location
export function getLocation(fullLocation) {
  // Verify if fullLocation is defined
  if (fullLocation) {

      const locationName = fullLocation.split('/')[1];
      
      // Corrigindo a comparação sem a barra inicial
      if (locationName === 'cobran%C3%A7as') {
          return '/cobranças';
      }
      
      return '/' + locationName;
  } else {
      return '';
  }
}

// ColorMapping to render the notification right styles
export const colorMapping = {
    1: {
        iconColor: '#CD4747',
        backgroundColor: themeColors.red01,
        textColor: '#4E1B1D',
    },
    2: {
        iconColor: '#DEA73F',
        backgroundColor: themeColors.yellow01,
        textColor: '#8D4D1B',
    },
    3: {
        iconColor: '#8142FC',
        backgroundColor: '#DDD4FF',
        textColor: '#4F189A',
    },
    4: {
        iconColor: '#4C8435',
        backgroundColor: '#D3EAC8',
        textColor: '#345427',
    },
    5: {
        iconColor: '#848484',
        backgroundColor: '#E5E5E5',
        textColor: '#303035',
    },
};

// Function to format the date in the notify Component
export function formatNotifyDate(dateString) {
  const months = [
      "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho",
      "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
  ];

  const [day, month, ano] = dateString.split("/");

  return `${day} ${months[parseInt(month, 10) - 1]}`;
}