// React
import React, { useState } from 'react';

// Material UI Components
import { Box, Button, MenuItem, Select, Typography, styled, Dialog } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

// Components and functions for the project
import { steps } from '../index.js';
import Titles from '../../../components/Titles/Titles.js';
import themeColors from '../../../components/ThemeColors/ThemeColors.js';

// mock plans
const plans = [
    {
        planName: 'FreeZap',
        planPrice: '0,00',
        channels: ['WhatsApp', 'Email'],
        messagesPerMonth: 600,
        features: ['Auto-resposta', 'painel-gerenciamento']
    },
    {
        planName: 'StartZap',
        planPrice: '99,90',
        channels: ['WhatsApp', 'Email'],
        messagesPerMonth: 2000,
        features: ['Auto-resposta', 'Painel Gerenciamento', 'Relatórios com I.A']
    },
    {
        planName: 'ProZap',
        planPrice: '249,90',
        channels: ['WhatsApp', 'Email', 'SMS'],
        messagesPerMonth: 4000,
        features: ['Auto-resposta', 'Painel Gerenciamento', 'Relatórios com I.A', 'Integrações com API']
    },
    {
        planName: 'UltraZap',
        planPrice: '449,90',
        channels: ['WhatsApp', 'Email', 'WhatsApp Business', 'SMS'],
        messagesPerMonth: 'ilimitadas',
        features: ['Auto-resposta', 'Painel Gerenciamento', 'Relatórios com I.A', 'Integrações com API']
    },
];

// Customizating the Dialog Component
const CustomDialog = styled(Dialog)(({ theme }) => ({
    '& .css-1hju3x6-MuiPaper-root-MuiDialog-paper': {
        borderRadius: "20px",
        width: "300px",
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    },
    '& .css-hz1bth-MuiDialog-container': {
        height: "100vh",
        width: "100vw",
        position: "fixed",
        top: "0",
        left: "0",
        display: "block",
    }
}))
const PlanCard = ({ plan, selectedPlan, setShowModal, setPlanToShow, setSelectedPlan }) => {

    // Handle card click (select the plan without opening the modal)
    const handleCardClick = () => {
        setSelectedPlan(plan);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                padding: '10px',
                border: `1px solid ${plan === selectedPlan ? themeColors.purple400 : themeColors.gray30}`,
                background: `${plan === selectedPlan ? '#F5F3FF' : '#FFF'}`,
                borderRadius: '6px',
                width: '100%',
                gap: '10px',
                cursor: 'pointer',
                transition: '.2s',
                justifyContent: 'space-between'
            }}
            onClick={handleCardClick} 
        >
            <Box display={'flex'} flexDirection={'column'}>
                <Titles children={plan.planName} bold type="P1" />
                <Titles children={`R$ ${plan.planPrice}/m`} color={themeColors.gray50} type="P1" />
            </Box>
            <Box
                onClick={(e) => {
                    e.stopPropagation();
                    setShowModal(true);
                    setPlanToShow(plan);
                }}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                }}
            >
                <InfoIcon sx={{ color: themeColors.purple500 , fontSize: '30px' }} />
            </Box>
        </Box>
    )
}

const PlanModal = ({ isOpen, onClose, plan, setSelectedPlan }) => {

    const closeModal = () => {
        onClose(false);
    };

    return (
        <CustomDialog
            open={isOpen}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="sx"
            onBackdropClick={closeModal}
        >
            <Box 
                sx={{
                    background: '#fff',
                    padding: '30px 20px',
                    display: 'flex',
                    flexDirection: 'column',
                    minWidth: '100%',
                    gap: '3px'
                }}
            >
                <Titles type={'H4'} children={`R$ ${plan?.planPrice} /mês`} bold styles={{ marginBottom: '10px' }} />
                <Titles type={'P1'} children={`${plan?.planName}`} bold styles={{ marginBottom: '20px' }} color={themeColors.purple500} />
                <Titles children={`Acesso aos canais: ${plan?.channels.join(', ')}`} color={themeColors.gray40} />
                <Titles children={`Funcionalidades: ${plan?.features.join(', ')}`} color={themeColors.gray40} />
                <Titles children={`${plan?.messagesPerMonth} mensagens por mês`} color={themeColors.gray40} />
                
                <Button onClick={() => {
                    setSelectedPlan(plan);
                    closeModal();
                }} variant='contained' sx={{ marginTop: '20px', borderRadius: '20px' }}>
                    Selecionar
                </Button>
            </Box>
        </CustomDialog>
    )
}

const Step5 = ({ message, selectedPlan, setSelectedPlan, paymentForm, setPaymentForm }) => {
    const [showModal, setShowModal] = useState(false);
    const [planToShow, setPlanToShow] = useState(null);

    return (
        <>
            <PlanModal plan={planToShow} isOpen={showModal} onClose={() => setShowModal(false)} setSelectedPlan={setSelectedPlan} />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden'
                }}
            >
                <Titles
                    styles={{
                        fontSize: { xs: '20px', lg: '24px' }
                    }}
                    children={steps[4].stepTitle}
                    bold
                    color={themeColors.purple500}
                />
                <Titles
                    styles={{ marginBottom: '2px', width: '100%', fontSize: { xs: '14px', lg: '16px' } }}
                    children={'Agora vamos escolher um plano para você.'}
                    color={themeColors.gray50}
                />
                <Titles
                    styles={{ marginBottom: '30px', width: '100%', fontSize: { xs: '14px', lg: '16px' } }}
                    children={'Você poderá testar o plano escolhido por 7 dias gratuitamente.'}
                    color={themeColors.gray50}
                />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '16px',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '8px',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'grid',
                                gap: '16px',
                                gridTemplateColumns: 'repeat(2, 1fr)'
                            }}
                        >
                            {plans.map((plan) => <PlanCard 
                                key={plan.planName} 
                                setPlanToShow={setPlanToShow} 
                                plan={plan} 
                                setSelectedPlan={setSelectedPlan} 
                                selectedPlan={selectedPlan} 
                                setShowModal={setShowModal} 
                            />)}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
};

export default Step5;