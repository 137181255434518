// React
import React from "react";

// Material UI Components
import { Box, TextField, Typography } from "@mui/material";

// Components and functions for the project
import { steps } from "../index.js";
import Titles from "../../../components/Titles/Titles.js";
import themeColors from "../../../components/ThemeColors/ThemeColors.js";
import { insertMaskForCep } from "../../../utils/utils.js";

const Step3 = ({ message, cep, setCep, state, setState, street, setStreet, number, setNumber, city, setCity }) => {

    // Function to fetch the CEP information
    const fetchAddressInfo = async (cepToSearch) => {
        // Regex to validate the cep
        const validacep = /^[0-9]{8}$/;

        // Fetching the cep data
        if (validacep.test(cepToSearch)) {
            const res = await fetch(`https://viacep.com.br/ws/${cepToSearch}/json/`);

            if (!res.ok) throw new Error('Ocorreu algum erro, tente novamente mais tarde');

            const addressInfo = await res.json();

            setState(addressInfo.estado);
            setCity(addressInfo.localidade);
            setStreet(addressInfo.logradouro);
        } else {
            return
        }
    }

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
            }}
        >
            <Titles
                styles={{
                    fontSize: { xs: '20px', lg: '24px' }
                }}
                children={steps[2].stepTitle}
                bold
                color={themeColors.purple500}
            />
            <Titles
                styles={{ marginBottom: "46px", fontSize: { xs: '14px', lg: '16px' } }}
                children={"Preencha os campos abaixo com seu endereço."}
                color={themeColors.gray50}
            />
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                }}
            >
                <Box>
                    <Typography
                        sx={{
                            color: "#52525B",
                            fontSize: "14px",
                            fontFamily: "Satoshi",
                            marginBottom: 0,
                        }}
                    >
                        CEP
                    </Typography>
                    <TextField
                        variant="outlined"
                        placeholder="Digite seu CEP"
                        fullWidth
                        margin="normal"
                        name="cep"
                        value={insertMaskForCep(cep)}
                        autoFocus={true}
                        autoComplete="off"
                        size="small"
                        onChange={(e) => {
                            const onlyNumber = e.target.value.replace(/\D/g, "");
                            setCep(onlyNumber);
                            fetchAddressInfo(onlyNumber);
                        }}
                        sx={{
                            background:
                                message === "Por favor, preencha todos os campos." &&
                                    cep === ""
                                    ? "#F8D6D3"
                                    : "#F9F9F9",
                            marginTop: "5px",
                            height: "35px",
                            marginBottom: "0px",
                            borderRadius: '6px',
                            '.css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
                                padding: '9px 16px',
                                height: '35px'
                            },
                            '.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input': {
                                padding: '5px 16px'
                            }
                        }}
                        InputProps={{
                            endAdornment:
                                (message === "Por favor, preencha todos os campos." &&
                                    cep === "") ? (
                                    <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <mask
                                            id="mask0_34_1183"
                                            maskUnits="userSpaceOnUse"
                                            x="0"
                                            y="0"
                                            width="20"
                                            height="20"
                                        >
                                            <rect width="20" height="20" fill="#D9D9D9" />
                                        </mask>
                                        <g mask="url(#mask0_34_1183)">
                                            <path
                                                d="M8 10.9743C8.11622 10.9743 8.21367 10.935 8.29233 10.8563C8.371 10.7778 8.41033 10.6804 8.41033 10.5642C8.41033 10.4478 8.371 10.3504 8.29233 10.2718C8.21367 10.1932 8.11622 10.1538 8 10.1538C7.88378 10.1538 7.78633 10.1932 7.70767 10.2718C7.629 10.3504 7.58967 10.4478 7.58967 10.5642C7.58967 10.6804 7.629 10.7778 7.70767 10.8563C7.78633 10.935 7.88378 10.9743 8 10.9743ZM7.66667 8.76917H8.33333V4.76917H7.66667V8.76917ZM8.00217 14C7.1725 14 6.39244 13.8426 5.662 13.5277C4.93167 13.2128 4.29633 12.7854 3.756 12.2457C3.21567 11.7059 2.78794 11.0711 2.47283 10.3413C2.15761 9.61167 2 8.83194 2 8.00217C2 7.1725 2.15744 6.39244 2.47233 5.662C2.78722 4.93167 3.21456 4.29633 3.75433 3.756C4.29411 3.21567 4.92889 2.78794 5.65867 2.47283C6.38833 2.15761 7.16806 2 7.99783 2C8.8275 2 9.60756 2.15744 10.338 2.47233C11.0683 2.78722 11.7037 3.21456 12.244 3.75433C12.7843 4.29411 13.2121 4.92889 13.5272 5.65867C13.8424 6.38833 14 7.16806 14 7.99783C14 8.8275 13.8426 9.60756 13.5277 10.338C13.2128 11.0683 12.7854 11.7037 12.2457 12.244C11.7059 12.7843 11.0711 13.2121 10.3413 13.5272C9.61167 13.8424 8.83194 14 8.00217 14Z"
                                                fill="#CD4747"
                                            />
                                        </g>
                                    </svg>
                                ) : null,
                        }}
                    />
                </Box>
                <Box display={'flex'} gap={'16px'}>
                    <Box>
                        <Typography
                            sx={{
                                color: "#52525B",
                                fontSize: "14px",
                                fontFamily: "Satoshi",
                                marginBottom: 0,
                            }}
                        >
                            Estado
                        </Typography>
                        <TextField
                            variant="outlined"
                            placeholder="Digite seu Estado"
                            margin="normal"
                            name="state"
                            value={state}
                            size="small"
                            onChange={(e) => {
                                const value = e.target.value;
                                const onlyText = value.replace(/\d+/g, "");
                                setState(onlyText);
                            }}
                            sx={{
                                background:
                                    message === "Por favor, preencha todos os campos." &&
                                        state === ""
                                        ? "#F8D6D3"
                                        : "#F9F9F9",
                                marginTop: "5px",
                                height: "35px",
                                marginBottom: "0px",
                                borderRadius: '6px',
                                '.css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
                                    padding: '9px 16px',
                                    height: '35px'
                                },
                                '.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input': {
                                    padding: '5px 16px'
                                }
                            }}
                            InputProps={{
                                endAdornment:
                                    (message === "Por favor, preencha todos os campos." &&
                                        cep === "") ? (
                                        <svg
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <mask
                                                id="mask0_34_1183"
                                                maskUnits="userSpaceOnUse"
                                                x="0"
                                                y="0"
                                                width="20"
                                                height="20"
                                            >
                                                <rect width="20" height="20" fill="#D9D9D9" />
                                            </mask>
                                            <g mask="url(#mask0_34_1183)">
                                                <path
                                                    d="M8 10.9743C8.11622 10.9743 8.21367 10.935 8.29233 10.8563C8.371 10.7778 8.41033 10.6804 8.41033 10.5642C8.41033 10.4478 8.371 10.3504 8.29233 10.2718C8.21367 10.1932 8.11622 10.1538 8 10.1538C7.88378 10.1538 7.78633 10.1932 7.70767 10.2718C7.629 10.3504 7.58967 10.4478 7.58967 10.5642C7.58967 10.6804 7.629 10.7778 7.70767 10.8563C7.78633 10.935 7.88378 10.9743 8 10.9743ZM7.66667 8.76917H8.33333V4.76917H7.66667V8.76917ZM8.00217 14C7.1725 14 6.39244 13.8426 5.662 13.5277C4.93167 13.2128 4.29633 12.7854 3.756 12.2457C3.21567 11.7059 2.78794 11.0711 2.47283 10.3413C2.15761 9.61167 2 8.83194 2 8.00217C2 7.1725 2.15744 6.39244 2.47233 5.662C2.78722 4.93167 3.21456 4.29633 3.75433 3.756C4.29411 3.21567 4.92889 2.78794 5.65867 2.47283C6.38833 2.15761 7.16806 2 7.99783 2C8.8275 2 9.60756 2.15744 10.338 2.47233C11.0683 2.78722 11.7037 3.21456 12.244 3.75433C12.7843 4.29411 13.2121 4.92889 13.5272 5.65867C13.8424 6.38833 14 7.16806 14 7.99783C14 8.8275 13.8426 9.60756 13.5277 10.338C13.2128 11.0683 12.7854 11.7037 12.2457 12.244C11.7059 12.7843 11.0711 13.2121 10.3413 13.5272C9.61167 13.8424 8.83194 14 8.00217 14Z"
                                                    fill="#CD4747"
                                                />
                                            </g>
                                        </svg>
                                    ) : null,
                            }}
                        />
                    </Box>
                    <Box>
                        <Typography
                            sx={{
                                color: "#52525B",
                                fontSize: "14px",
                                fontFamily: "Satoshi",
                                marginBottom: 0,
                            }}
                        >
                            Cidade
                        </Typography>
                        <TextField
                            variant="outlined"
                            placeholder="Digite sua cidade"
                            margin="normal"
                            name="state"
                            value={city}
                            size="small"
                            onChange={(e) => {
                                const value = e.target.value;
                                const onlyText = value.replace(/\d+/g, "");
                                setCity(onlyText);
                            }}
                            sx={{
                                background:
                                    message === "Por favor, preencha todos os campos." &&
                                        city === ""
                                        ? "#F8D6D3"
                                        : "#F9F9F9",
                                marginTop: "5px",
                                height: "35px",
                                marginBottom: "0px",
                                borderRadius: '6px',
                                '.css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
                                    padding: '9px 16px',
                                    height: '35px'
                                },
                                '.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input': {
                                    padding: '5px 16px'
                                }
                            }}
                            InputProps={{
                                endAdornment:
                                    (message === "Por favor, preencha todos os campos." &&
                                        cep === "") ? (
                                        <svg
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <mask
                                                id="mask0_34_1183"
                                                maskUnits="userSpaceOnUse"
                                                x="0"
                                                y="0"
                                                width="20"
                                                height="20"
                                            >
                                                <rect width="20" height="20" fill="#D9D9D9" />
                                            </mask>
                                            <g mask="url(#mask0_34_1183)">
                                                <path
                                                    d="M8 10.9743C8.11622 10.9743 8.21367 10.935 8.29233 10.8563C8.371 10.7778 8.41033 10.6804 8.41033 10.5642C8.41033 10.4478 8.371 10.3504 8.29233 10.2718C8.21367 10.1932 8.11622 10.1538 8 10.1538C7.88378 10.1538 7.78633 10.1932 7.70767 10.2718C7.629 10.3504 7.58967 10.4478 7.58967 10.5642C7.58967 10.6804 7.629 10.7778 7.70767 10.8563C7.78633 10.935 7.88378 10.9743 8 10.9743ZM7.66667 8.76917H8.33333V4.76917H7.66667V8.76917ZM8.00217 14C7.1725 14 6.39244 13.8426 5.662 13.5277C4.93167 13.2128 4.29633 12.7854 3.756 12.2457C3.21567 11.7059 2.78794 11.0711 2.47283 10.3413C2.15761 9.61167 2 8.83194 2 8.00217C2 7.1725 2.15744 6.39244 2.47233 5.662C2.78722 4.93167 3.21456 4.29633 3.75433 3.756C4.29411 3.21567 4.92889 2.78794 5.65867 2.47283C6.38833 2.15761 7.16806 2 7.99783 2C8.8275 2 9.60756 2.15744 10.338 2.47233C11.0683 2.78722 11.7037 3.21456 12.244 3.75433C12.7843 4.29411 13.2121 4.92889 13.5272 5.65867C13.8424 6.38833 14 7.16806 14 7.99783C14 8.8275 13.8426 9.60756 13.5277 10.338C13.2128 11.0683 12.7854 11.7037 12.2457 12.244C11.7059 12.7843 11.0711 13.2121 10.3413 13.5272C9.61167 13.8424 8.83194 14 8.00217 14Z"
                                                    fill="#CD4747"
                                                />
                                            </g>
                                        </svg>
                                    ) : null,
                            }}
                        />
                    </Box>
                </Box>
                <Box display={'flex'} gap={'16px'}>
                    <Box>
                        <Typography
                            sx={{
                                color: "#52525B",
                                fontSize: "14px",
                                fontFamily: "Satoshi",
                                marginBottom: 0,
                            }}
                        >
                            Rua
                        </Typography>
                        <TextField
                            variant="outlined"
                            placeholder="Digite sua Rua"
                            margin="normal"
                            name="street"
                            value={street}
                            size="small"
                            onChange={(e) => {
                                const value = e.target.value;
                                const onlyText = value.replace(/\d+/g, "");
                                setStreet(onlyText)
                            }}
                            sx={{
                                background:
                                    message === "Por favor, preencha todos os campos." &&
                                        street === ""
                                        ? "#F8D6D3"
                                        : "#F9F9F9",
                                marginTop: "5px",
                                height: "35px",
                                marginBottom: "0px",
                                borderRadius: '6px',
                                position: 'relative',
                                '.css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
                                    padding: '9px 16px',
                                    height: '35px'
                                },
                                '.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input': {
                                    padding: '5px 16px'
                                }
                            }}
                            InputProps={{
                                endAdornment:
                                    (message === "Por favor, preencha todos os campos." &&
                                        street === "") ? (
                                        <svg
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <mask
                                                id="mask0_34_1183"
                                                maskUnits="userSpaceOnUse"
                                                x="0"
                                                y="0"
                                                width="20"
                                                height="20"
                                            >
                                                <rect width="20" height="20" fill="#D9D9D9" />
                                            </mask>
                                            <g mask="url(#mask0_34_1183)">
                                                <path
                                                    d="M8 10.9743C8.11622 10.9743 8.21367 10.935 8.29233 10.8563C8.371 10.7778 8.41033 10.6804 8.41033 10.5642C8.41033 10.4478 8.371 10.3504 8.29233 10.2718C8.21367 10.1932 8.11622 10.1538 8 10.1538C7.88378 10.1538 7.78633 10.1932 7.70767 10.2718C7.629 10.3504 7.58967 10.4478 7.58967 10.5642C7.58967 10.6804 7.629 10.7778 7.70767 10.8563C7.78633 10.935 7.88378 10.9743 8 10.9743ZM7.66667 8.76917H8.33333V4.76917H7.66667V8.76917ZM8.00217 14C7.1725 14 6.39244 13.8426 5.662 13.5277C4.93167 13.2128 4.29633 12.7854 3.756 12.2457C3.21567 11.7059 2.78794 11.0711 2.47283 10.3413C2.15761 9.61167 2 8.83194 2 8.00217C2 7.1725 2.15744 6.39244 2.47233 5.662C2.78722 4.93167 3.21456 4.29633 3.75433 3.756C4.29411 3.21567 4.92889 2.78794 5.65867 2.47283C6.38833 2.15761 7.16806 2 7.99783 2C8.8275 2 9.60756 2.15744 10.338 2.47233C11.0683 2.78722 11.7037 3.21456 12.244 3.75433C12.7843 4.29411 13.2121 4.92889 13.5272 5.65867C13.8424 6.38833 14 7.16806 14 7.99783C14 8.8275 13.8426 9.60756 13.5277 10.338C13.2128 11.0683 12.7854 11.7037 12.2457 12.244C11.7059 12.7843 11.0711 13.2121 10.3413 13.5272C9.61167 13.8424 8.83194 14 8.00217 14Z"
                                                    fill="#CD4747"
                                                />
                                            </g>
                                        </svg>
                                    ) : null,
                            }}
                        />
                    </Box>
                    <Box>
                        <Typography
                            sx={{
                                color: "#52525B",
                                fontSize: "14px",
                                fontFamily: "Satoshi",
                                marginBottom: 0,
                            }}
                        >
                            Número
                        </Typography>
                        <TextField
                            variant="outlined"
                            placeholder="Digite o Número"
                            margin="normal"
                            name="number"
                            value={number}
                            size="small"
                            onChange={(e) => {
                                const onlyNumber = e.target.value.replace(/\D/g, "");
                                setNumber(onlyNumber)
                            }}
                            sx={{
                                background:
                                    message === "Por favor, preencha todos os campos." &&
                                        number === ""
                                        ? "#F8D6D3"
                                        : "#F9F9F9",
                                marginTop: "5px",
                                height: "35px",
                                marginBottom: "0px",
                                borderRadius: '6px',
                                '.css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
                                    padding: '9px 16px',
                                    height: '35px'
                                },
                                '.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input': {
                                    padding: '5px 16px'
                                }
                            }}
                            InputProps={{
                                endAdornment:
                                    (message === "Por favor, preencha todos os campos." &&
                                        number === "") ? (
                                        <svg
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <mask
                                                id="mask0_34_1183"
                                                maskUnits="userSpaceOnUse"
                                                x="0"
                                                y="0"
                                                width="20"
                                                height="20"
                                            >
                                                <rect width="20" height="20" fill="#D9D9D9" />
                                            </mask>
                                            <g mask="url(#mask0_34_1183)">
                                                <path
                                                    d="M8 10.9743C8.11622 10.9743 8.21367 10.935 8.29233 10.8563C8.371 10.7778 8.41033 10.6804 8.41033 10.5642C8.41033 10.4478 8.371 10.3504 8.29233 10.2718C8.21367 10.1932 8.11622 10.1538 8 10.1538C7.88378 10.1538 7.78633 10.1932 7.70767 10.2718C7.629 10.3504 7.58967 10.4478 7.58967 10.5642C7.58967 10.6804 7.629 10.7778 7.70767 10.8563C7.78633 10.935 7.88378 10.9743 8 10.9743ZM7.66667 8.76917H8.33333V4.76917H7.66667V8.76917ZM8.00217 14C7.1725 14 6.39244 13.8426 5.662 13.5277C4.93167 13.2128 4.29633 12.7854 3.756 12.2457C3.21567 11.7059 2.78794 11.0711 2.47283 10.3413C2.15761 9.61167 2 8.83194 2 8.00217C2 7.1725 2.15744 6.39244 2.47233 5.662C2.78722 4.93167 3.21456 4.29633 3.75433 3.756C4.29411 3.21567 4.92889 2.78794 5.65867 2.47283C6.38833 2.15761 7.16806 2 7.99783 2C8.8275 2 9.60756 2.15744 10.338 2.47233C11.0683 2.78722 11.7037 3.21456 12.244 3.75433C12.7843 4.29411 13.2121 4.92889 13.5272 5.65867C13.8424 6.38833 14 7.16806 14 7.99783C14 8.8275 13.8426 9.60756 13.5277 10.338C13.2128 11.0683 12.7854 11.7037 12.2457 12.244C11.7059 12.7843 11.0711 13.2121 10.3413 13.5272C9.61167 13.8424 8.83194 14 8.00217 14Z"
                                                    fill="#CD4747"
                                                />
                                            </g>
                                        </svg>
                                    ) : null,
                            }}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default Step3;