// Description: This component render a Input that will receive and change a value
// Update: 18/11/2024
// Author: Simon Franklin

// React Components and DOM Elements
import React, { useEffect, useRef, useState } from "react";

// Material UI Components
import {
  Grid,
  FormControl,
  TextField,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  MenuItem,
} from "@mui/material";

// Components and functions for the project
import themeColors from "../ThemeColors/ThemeColors";
import Titles from "../Titles/Titles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { updatedField } from "../../utils/utils.js";

// Import Styles
import './TemplateInput.css'

// Render The component
const TemplateInput = ({
  field,
  handleInputChange,
  variablesOfTemplate,
  toggleAccordionVisibility,
  handleChangeAccordion,
  addVariableInField,
  formState,
  isVisible,
  expanded,
  setExpanded
}) => {
  const [formattedText, setFormattedText] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const editorRef = useRef(null);
  const [placeholder, setPlaceholder] = useState(translateString(field));

  useEffect(() => {
    setFormattedText(updatedField(formState[field], variablesOfTemplate));
  }, [formState]);

  useEffect(() => {
    if (isFocused && editorRef.current) {
      const range = document.createRange();
      const sel = window.getSelection();
      range.selectNodeContents(editorRef.current);
      range.collapse(false); // Coloca o cursor no final
      sel.removeAllRanges();
      sel.addRange(range);
    }
  }, [formattedText, isFocused]);

  function translateString(input) {
    if (input.includes("Title")) {
      return "Seu Título";
    } else if (input.includes("Text")) {
      return "Seu Texto";
    } else {
      return input; // Retorna a string original se não contiver "Title" ou "Text"
    }
  }

  function translateInputHeight(input) {
    if (input.includes("Title")) {
      return "51px";
    } else if (input.includes("Text")) {
      return "240px";
    } else {
      return "51px";
    }
  }

  const closeAccordion = () => {
    setExpanded(prevExpanded => ({
      ...prevExpanded,
      [field]: null
    }));
  };

  const editorStyle = {
    backgroundColor: "white",
    color: formState[field] ? "black" : themeColors.gray40,
    borderRadius: "12px",
    margin: 0,
    border: `1px solid ${themeColors.gray20}`,
    minHeight: "40px",
    whiteSpace: "pre-wrap",
    padding: "14px",
    maxHeight: translateInputHeight(field),
    minHeight: translateInputHeight(field),
    outline: "none",
    overflowY: field.includes("Text") ? "scroll" : "default"
  };

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      gap={"5px"}
      minWidth={"100%"}
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Titles children={field} color={"#52525B"} type={"P1"} />
        </Grid>
        {
          variablesOfTemplate && (
            <Grid item xs={6} style={{ position: "relative" }}>
              <FormControl fullWidth>
                <Typography
                  onClick={() => toggleAccordionVisibility(field)}
                  style={{
                    color: themeColors.purple500,
                    marginRight: "5px",
                    marginTop: "2px",
                    fontFamily: "Satoshi-Medium",
                    fontSize: "18px",
                    display: "flex",
                    justifyContent: "flex-end",
                    cursor: "pointer",
                  }}
                >
                  {isVisible[field]
                    ? "Esconder Variáveis"
                    : "+ Adicionar Variáveis"}
                </Typography>
                {isVisible[field] && (
                  <div
                    style={{
                      position: "absolute",
                      right: 0,
                      top: "100%",
                      width: "auto",
                      backgroundColor: themeColors.purple200,
                      boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
                      overflow: "hidden",
                      zIndex: 1,
                      borderRadius: "12px",
                    }}
                  >
                    {Object.entries(variablesOfTemplate).map(
                      ([category, values]) => (
                        <Accordion
                          key={category}
                          expanded={expanded[field] === category}
                          onChange={handleChangeAccordion(field, category)}
                          style={{
                            background: themeColors.purple200,
                            border: "none !important",
                          }}
                        >
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>{category}</Typography>
                          </AccordionSummary>
                          <AccordionDetails
                            style={{ border: "none !important", padding: 0 }}
                          >
                            {values.length > 0 ? (
                              values.map((value) => (
                                <MenuItem
                                  key={value}
                                  onClick={() => {
                                    addVariableInField(field, value);
                                    toggleAccordionVisibility(field);
                                    closeAccordion();
                                  }}
                                  style={{ color: themeColors.purple950 }}
                                >
                                  {value}
                                </MenuItem>
                              ))
                            ) : (
                              <Typography>Adicionar variáveis</Typography>
                            )}
                          </AccordionDetails>
                        </Accordion>
                      )
                    )}
                  </div>
                )}
              </FormControl>
            </Grid>
          )
        }
      </Grid>
      <div
        className="template_input"
        ref={editorRef}
        contentEditable={true}
        data-placeholder={translateString(field)}
        dangerouslySetInnerHTML={{ __html: formattedText }}
        onInput={(e) => handleInputChange({ target: { name: field, value: e.target.innerText } })}
        style={editorStyle}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
    </Box>
  );
};

export default TemplateInput;
